import React, { Component } from "react";
import PropTypes from "prop-types";
import View from "./View";
import { connect } from "react-redux";
import { getLayoutSettings, toggleLayoutSettings } from "../../../../../../modules/client";

const presets = [
  { settingsIndex: 1, leftPanel: true, dashboardPanel: true },
  { settingsIndex: 2, leftPanel: false, dashboardPanel: true },
  { settingsIndex: 3, leftPanel: true, dashboardPanel: false },
  { settingsIndex: 4, leftPanel: false, dashboardPanel: false },
];

const ViewTab = ({ toggleLayoutSettings, ...rest }) => {
  return (
    <View
      {...rest}
      onChangeValue={(selectedIndex) => {
        toggleLayoutSettings(presets.find(({ settingsIndex }) => selectedIndex === settingsIndex));
      }}
    />
  );
};

export default connect(
  (state) => ({
    ...getLayoutSettings(state),
  }),
  { toggleLayoutSettings }
)(ViewTab);

class LayoutSettings extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    settingsIndex: PropTypes.number.isRequired,
  };

  handleChange = (selectedIndex) => {
    this.props.toggleLayoutSettings(presets.find(({ settingsIndex }) => selectedIndex === settingsIndex));
  };

  render() {
    const { settingsIndex } = this.props;
    return <View {...this.props} {...{ settingsIndex, onChangeValue: this.handleChange }} />;
  }
}
