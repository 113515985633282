import styled, { css } from "styled-components";
import closeIcon from "./assets/close-btn.svg";

const Window = ({ isActive, title, children, width, height, handleClose, enableClose, hideMenu }) => {
  return (
    <Overlay {...{ isActive, hideMenu }}>
      <Container {...{ width, height }}>
        <Header>
          <Empty />
          <HeaderText>{title}</HeaderText>
          {enableClose ? <CloseBtn onClick={handleClose} /> : <Empty />}
        </Header>
        <Content>{children}</Content>
      </Container>
    </Overlay>
  );
};

const Overlay = styled.div`
  background: rgba(35, 41, 56, 0.8);
  backdrop-filter: blur(5px);
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 11;
  ${(props) =>
    props.isActive
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
  ${(props) =>
    props.hideMenu
      ? css`
          width: 100%;
        `
      : css`
          width: calc(100% - 36px);
        `}
`;
const Container = styled.div`
  ${(props) => css`
    width: ${props.width}px;
    height: ${props.height}px;
  `}
  background-color: #2d364e;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  overflow-x: hidden;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 35px;
  background: #2d364e;
  box-shadow: 0 0.5px 0 rgba(0, 0, 0, 0.2), inset 0px -0.5px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px 10px 0 0;
`;
const HeaderText = styled.div`
  color: #ebebf5;
  opacity: 0.6;
  font-size: 13px;
`;
const CloseBtn = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 20px;
  cursor: pointer;
  ${() =>
    css`
      background-image: url(${closeIcon});
    `}
`;
const Content = styled.div`
  height: calc(100% - 35px);
`;
const Empty = styled.div``;

export default Window;
