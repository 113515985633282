import React, { useState } from "react";
import styled from "styled-components";
import { components } from "react-select";

import ErrorMessage from "../../../../../base/form/ErrorMessage";
import Button from "../../../../../base/form/Button";
import Input from "../../../../../base/form/Input";
import CheckBox from "../../../../../base/form/CheckBox";
import Select from "../../../../../base/form/Select";

import iconLock from "./assets/icon-lock.svg";

const AccountSettings = ({
  disabled,
  selectOptions,
  selected,
  onSelectChange,
  onSelectCreateOption,
  activeProvider,
  ordersPositionSize,
  applySettingsError,
  quickBuyStatus,
  isSelectCreatable,
  onPositionSizeChange,
  onToggleQuickBuy,
  onApply,
}) => {
  const handleSelectInputChange = (inputValue, event) => {
    if (event.action === "input-change" && isSelectCreatable) {
      if (/^\d*$/.test(inputValue)) {
        return inputValue;
      } else {
        return inputValue.substring(0, inputValue.length - 1);
      }
    }

    return inputValue;
  };

  const handleSelectInputSubmit = (e) => {
    if (e.charCode === 13) {
      onSelectCreateOption(e.target.value);
    }
  };

  return (
    <Container disabled={disabled}>
      <Title>Select {activeProvider.fields.select.label}</Title>
      <StyledSelect
        background={"#454C63"}
        maxMenuHeight={40}
        options={selectOptions}
        value={selected || "Select..."}
        onChange={onSelectChange}
        onInputChange={handleSelectInputChange}
        components={{
          Input: (props) => <components.Input {...props} onKeyPress={handleSelectInputSubmit} />,
        }}
      />
      <OrdersPositionSize>
        <Title>Orders postion size [{activeProvider.fields.ordersPositionSize.unit.label}]</Title>
        <Line>
          {ordersPositionSize.map((position, i) => (
            <InputWrapper key={i}>
              <Input
                value={position.value}
                error={position.error}
                disabled={disabled}
                onChange={(e) => onPositionSizeChange(i, e.target.value)}
              />
            </InputWrapper>
          ))}
        </Line>
        <CheckBoxWrapper>
          <CheckBox
            disabled={disabled}
            value={quickBuyStatus}
            onChange={onToggleQuickBuy}
            label="QuickBuy (Do not ask conformation)"
          />
        </CheckBoxWrapper>
        {/*<Line>*/}
        {/*  <StyledButton disabled={disabled} onClick={onApply}>*/}
        {/*    Apply*/}
        {/*  </StyledButton>*/}
        {/*  {applySettingsError && (*/}
        {/*    <ErrorWrapper>*/}
        {/*      <ErrorMessage errorText={applySettingsError} />*/}
        {/*    </ErrorWrapper>*/}
        {/*  )}*/}
        {/*</Line>*/}
      </OrdersPositionSize>
      <DisabledOverlay isActive={disabled}>
        <IconLock src={iconLock} />
      </DisabledOverlay>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  & div {
    color: ${(props) => (props.disabled ? "rgba(255, 255, 255, 0.17)" : "white")};
  }
`;

const Title = styled.div`
  color: white;
  font-size: 15px;
  font-weight: 500;
  padding-left: 2px;
  margin-bottom: 10px;
`;

const StyledSelect = styled(Select)`
  width: 40%;
  margin-right: 20px;
`;

const OrdersPositionSize = styled.div`
  width: 100%;
  margin-top: 15px;
`;

const Line = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin-right: 10px;
  width: 180px;
`;

const StyledButton = styled(Button)`
  width: 140px;
  height: 35px;
  padding: 10px 20px;
  margin-top: 8px;
  margin-right: 15px;
`;

const CheckBoxWrapper = styled.div`
  margin: 15px 0;
`;

const DisabledOverlay = styled.div`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
`;

const IconLock = styled.img`
  position: relative;
  top: -32px;
  width: 18px;
  height: 18px;
`;

const ErrorWrapper = styled.div`
  & div {
    color: #ff3d71 !important;
  }
`;

export default AccountSettings;
