import { createAction } from "redux-actions";

const PREFIX = "FILTERS_SETTINGS";

export const setFiltersAsset = createAction(`${PREFIX}/SET_ASSET`);

export const deletePreset = createAction(`${PREFIX}/DELETE_PRESET`);
export const changePreset = createAction(`${PREFIX}/CHANGE_PRESET`);
export const setPresetError = createAction(`${PREFIX}/SET_PRESET_ERROR`);

export const toggleSignalFavorite = createAction(`${PREFIX}/ADD_SIGNAL_TO_FAVORITES`);
