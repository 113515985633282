import { receiveSignals as receiveSignalsAction } from "./actions";
import { isDebug } from "../initial";

export const receiveSignals = (payload) => async (dispatch, getState) => {
  const debugMode = isDebug(getState());
  if (debugMode) {
    // console.log('received_signals', payload);
  }

  dispatch(receiveSignalsAction(payload));
};
