import React, { useState } from "react";
import styled from "styled-components";
import RadioButton from "../../../../../base/form/Radio";
import Input from "../../../../../base/form/Input";
import Button from "../../../../../base/form/Button";
import iconDefSettings from "../../../../../base/assets/icon-default-settings.svg";
import { openLink } from "../../../../../../utils";
import params from "../../../../../../config";
import ErrorMessage from "../../../../../base/form/ErrorMessage";

const View = ({
  isActive,
  isAuth,
  link,
  handleApplyLicenseKey,
  onFormFieldChange,
  clientRate,
  licenseKey,
  newLicenseKey,
  removeLicenseKey,
  onApplyDefault,
  isLicenseKeyError,
  login,
  password,
}) => {
  const [activeLicenseType, setActiveLicenseType] = useState(1);
  const handleSwitchLicenseType = () => {
    if (isAuth) return;
    setActiveLicenseType(activeLicenseType * -1);
  };

  const handleApplyClicked = () => {
    if (newLicenseKey.length < 1 && (login.length < 1 || password.length < 1)) return;

    handleApplyLicenseKey();
  };

  return (
    <Container>
      <AuthSelector>
        <RadioButton
          onClick={handleSwitchLicenseType}
          active={activeLicenseType === 1}
          disabled={isAuth}
          label="License Key"
          style={{ float: "left", width: "50%" }}
        />
        <RadioButton
          onClick={handleSwitchLicenseType}
          active={activeLicenseType === -1}
          disabled={isAuth}
          label={`${params.appName} account`}
          style={{ float: "right", width: "50%" }}
        />
      </AuthSelector>
      <FormApply>
        {(activeLicenseType === 1 || isAuth) && (
          <Input
            label={"Enter license key"}
            image={"license-key"}
            type="text"
            disabled={licenseKey && licenseKey.length > 0}
            value={licenseKey && licenseKey.length > 0 ? licenseKey : newLicenseKey}
            name="newLicenseKey"
            onChange={onFormFieldChange}
          />
        )}
        {activeLicenseType === -1 && !isAuth && (
          <>
            <InputWrapper>
              <Input
                label={"Enter email"}
                image={"mail"}
                type="text"
                value={login}
                name="login"
                onChange={onFormFieldChange}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                label={"Enter password"}
                image={"lock"}
                type="password"
                value={password}
                name="password"
                onChange={onFormFieldChange}
              />
            </InputWrapper>
          </>
        )}
        <ApplyWrapper>
          <ApplyButton
            onClick={handleApplyClicked}
            disabled={newLicenseKey.length < 1 && (login.length < 1 || password.length < 1)}
          >
            Apply
          </ApplyButton>
          <ErrorContainer>
            {isLicenseKeyError && <ErrorMessage errorText="You entered an invalid or non-existent account" />}
          </ErrorContainer>
        </ApplyWrapper>
      </FormApply>
      <RateContainer>
        <RateTitle>Your Rate</RateTitle>
        <RateText>{clientRate ? clientRate.toUpperCase() : "FREE"}</RateText>
        {(!licenseKey || !licenseKey.length) && (
          <RateButton size={13} onClick={() => openLink(`${link}?utm_source=upgrade_pro_app`, () => {})}>
            Buy PRO
          </RateButton>
        )}
        {licenseKey && licenseKey.length > 0 && (
          <RateButton onClick={removeLicenseKey}>Delete license key</RateButton>
        )}
      </RateContainer>
      <DefaultSettingsButton onClick={onApplyDefault}>
        <ImgDefSettings src={iconDefSettings} />
        Apply default settings
      </DefaultSettingsButton>
      {/*<div className="default_settings">*/}
      {/*  Apply default settings*/}
      {/*</div>*/}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px 15px;
  height: 100%;
  width: 400px;
`;
const AuthSelector = styled.div``;
const FormApply = styled.div`
  padding: 40px 0 25px;
`;
const ApplyWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const ApplyButton = styled(Button)`
  width: 100px;
  padding: 10px 0;
`;
const RateContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 85px;
`;
const RateButton = styled(Button)`
  width: 200px;
  padding: 10px 0;
`;
const RateTitle = styled.div`
  color: rgba(255, 255, 255, 0.54);
  opacity: 0.5;
  font-size: 14px;
`;
const RateText = styled.div`
  font-size: 14px;
  color: #fff;
  font-weight: 700;
`;
const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
`;
const DefaultSettingsButton = styled.div`
  font-weight: 600;
  font-size: 15px;
  color: #407bff;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
`;
const ImgDefSettings = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;
const InputWrapper = styled.div`
  margin-bottom: 15px;
`;

export default View;
