import React from "react";
import styled, { css } from "styled-components";

import Select from "../../../../../base/form/Select";
import SelectOption from "./SelectOption";

import questionMarkIcon from "../../../../../base/assets/icon-question-mark.svg";

import AccountSettings from "./AccountSettings";
import ApiCreds from "./ApiCreds";

const View = ({
  howToConnectLink,
  providers,
  activeProvider,
  creds,
  selectOptions,
  selected,
  connectionStatus,
  ordersPositionSize,
  quickBuyStatus,
  connectionError,
  applySettingsError,
  isSelectCreatable,
  onProviderChange,
  onCredsChange,
  onSelectChange,
  onSelectCreateOption,
  onPositionSizeChange,
  onConnect,
  onToggleQuickBuy,
  onApply,
  onDeleteAccount,
}) => {
  return (
    <Container>
      <Brokers>
        <BetaVersion>Beta version</BetaVersion>
        <StyledSelect
          background={"#454C63"}
          options={providers}
          getOptionLabel={(provider) => provider.name}
          getOptionValue={(provider) => provider.name}
          components={{
            Option: (props) => <SelectOption {...props} onDeleteAccount={onDeleteAccount} />,
          }}
          placeholder="Brokers List"
          value={activeProvider}
          onChange={(option) => onProviderChange(option)}
        />
        {howToConnectLink && (
          <Link href={howToConnectLink} target="_blank" icon={questionMarkIcon}>
            How to connect
          </Link>
        )}
      </Brokers>
      <Divider />
      <ApiCreds
        creds={creds}
        connectionStatus={connectionStatus}
        connectionError={connectionError}
        onCredsChange={onCredsChange}
        onConnect={onConnect}
      />
      <Divider />
      <AccountSettings
        disabled={!connectionStatus}
        selectOptions={selectOptions}
        selected={selected}
        activeProvider={activeProvider}
        ordersPositionSize={ordersPositionSize}
        applySettingsError={applySettingsError}
        quickBuyStatus={quickBuyStatus}
        isSelectCreatable={isSelectCreatable}
        onSelectChange={onSelectChange}
        onSelectCreateOption={onSelectCreateOption}
        onPositionSizeChange={onPositionSizeChange}
        onToggleQuickBuy={onToggleQuickBuy}
        onApply={onApply}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17px 15px;
  height: 100%;
`;

const StyledSelect = styled(Select)`
  width: 40%;
  margin-right: 20px;
`;

const Brokers = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  flex: 0 0 auto;
`;

const BetaVersion = styled.div`
  position: absolute;
  right: 0;
  top: -10px;
  color: #1bc53c;
  font-size: 12px;
`;

const Link = styled.a`
  color: white;
  font-size: 12px;
  padding-left: 16px;
  ${(props) => css`
    background: no-repeat url(${props.icon});
    background-size: 13px;
  `};
  &:hover {
    cursor: pointer;
  }
`;

const Divider = styled.hr`
  width: 100%;
  height: 5px;
  border: none;
  border-top: 1px solid rgba(144, 144, 144, 0.2);
  margin: 20px 0;
`;

export default View;
