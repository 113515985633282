import { handleActions } from "redux-actions";
import { setAdditionalQuote } from "./actions";
import { combineReducers } from "redux";

const quotes = handleActions(
  {
    [setAdditionalQuote]: (state, action) => {
      return [action.payload, ...state.filter((quote) => quote.symbol !== action.payload.symbol)];
    },
  },
  []
);

export default quotes;
