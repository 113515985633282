import styled, { css } from "styled-components";
import Select from "../../../../../base/form/Select";
import Button from "../../../../../base/form/Button";
import CheckBox from "../../../../../base/form/CheckBox";
import eyeNoIcon from "../../../../../base/assets/icon-eye-no.svg";
import React from "react";

const availableIndicators = [
  { id: "summary", name: "Summary" },
  // { id: "zigzag", name: "Zigzag" },
  { id: "piv", name: "Pivot Points" },
  { id: "volatility", name: "Volatility" },
];

const buildOptions = (assets) => {
  return Object.entries(assets).map(([name, items], index) => {
    return {
      label: name.toUpperCase(),
      options: items.map(symbol => ({ label: symbol, value: symbol })),
    };
  });
};

const formatGroupLabel = data => (
  <div style={{
    fontSize: 13,
    borderBottom: "solid 1px #777",
    paddingBottom: 5,
  }}>
    {data.label}
  </div>
);
const DashboardSettingsItem = ({ enabled, symbol, isActive, visible, assets, index, setEnabled, setSymbol, setVisibleSettings, }) => {
  const disabled = !enabled;

  return (
    <Container isActive={isActive}>
      <Select
        value={{ label: symbol, value: symbol }}
        isDisabled={disabled}
        options={buildOptions(assets)}
        formatGroupLabel={formatGroupLabel}
        onChange={({ value }) => {
          setSymbol({ dashboardId: index, value });
        }}
      />
      {availableIndicators.map(({ id, name }) => (
        <CheckBox
          key={id} label={name}
          disabled={disabled} value={visible[id]}
          onChange={() => {
            setVisibleSettings({ dashboardId: index, value: {...visible, [id]: !visible[id]} })
          }}
        />
      ))}
      <Divider/>
      <ButtonStyled size={12} isWarning={!disabled} onClick={() => {
        setEnabled({ dashboardId: index, value: !enabled });
      }}
      >
        {!disabled && <ButtonIcon src={eyeNoIcon}/>}
        {disabled ? "Enable" : "Disable"}
      </ButtonStyled>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 17px 15px;
  width: 175px;
  height: 220px;
  background: #212A42;
  border-radius: 5px;
  ${props => css`border: solid 1px ${props.isActive ? "#999999" : `#212A42`};`}
`;
const ButtonStyled = styled(Button)`
  padding: 6px 2px;
  width: 100%;
  height: 28px;
  justify-content: center;
`;
const ButtonIcon = styled.img`
  width: 18px;
  height: 16px;
  margin-right: 5px;
`;
const Divider = styled.div`
  background-color: rgba(255, 255, 255, 0.4);
  height: 1px;
  width: 100%;
`;

export default DashboardSettingsItem;