import styled from "styled-components";
import iconKey from "../assets/icon-key.svg";
import iconLock from "../assets/icon-input-lock.svg";
import iconMail from "../assets/icon-mail.svg";
import iconBrokerName from "../assets/icon-broker-name.svg";
import iconBrokerUrl from "../assets/icon-broker-url.svg";

const icons = {
  "license-key": iconKey,
  lock: iconLock,
  mail: iconMail,
  "broker-name": iconBrokerName,
  "broker-url": iconBrokerUrl,
};

const Input = ({ label, image, buttonText, error, onClick, disabled, ...rest }) => {
  return (
    <Container>
      <Label>{label}</Label>
      {error && <ErrorText>{error}</ErrorText>}
      <StyledInputContainer error={!!error} disabled={disabled} withButton={!!buttonText}>
        <StyledInput disabled={disabled} {...rest} withButton={!!buttonText} />
        {buttonText && <Button onClick={onClick}>{buttonText}</Button>}
        {image && <Img src={icons[image]} />}
      </StyledInputContainer>
    </Container>
  );
};

const Container = styled.div`
  /* margin-bottom: 15px; */
  position: relative;
`;

const StyledInputContainer = styled.div`
  border: ${(props) => {
    if (props.error) {
      return "2px solid #E9655B";
    }
    return props.disabled ? `2px solid rgba(255, 255, 255, 0.17)` : `2px solid #9ea3ae`;
  }};
  width: 100%;
  border-radius: 5px;
  height: 35px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
`;
const StyledInput = styled.input`
  border: none;
  background-color: transparent;
  color: ${(props) => (props.disabled ? "rgba(255, 255, 255, 0.17)" : "#fff")};
  padding: 2px 10px;
  font-size: 15px;
  flex-grow: 0;
  flex-shrink: 1;
  min-width: 0px;
  /* width: 100%; */
  width: ${(props) => (props.withButton ? "auto" : "100%")};
`;
const Label = styled.label`
  font-size: 15px;
  color: #8f939b;
`;
const Img = styled.img`
  width: 30px;
  height: 30px;
`;
const Button = styled.button`
  position: relative;
  right: -2px;
  top: -2px;
  height: calc(100% + 4px);
  background-color: #0867e5;
  padding: 0 20px;
  color: white;
  border: none;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const ErrorText = styled.span`
  position: absolute;
  color: #e9655b;
  font-size: 11px;
  top: -12px;
  left: 5px;
  font-weight: 500;
`;

export default Input;
