import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import {
  newTab,
  closeTab,
  setActiveSignalsType,
  setActiveBroker,
  toggleLayoutSettings,
  setActiveTab,
  setTabTitle,
  updateTms,
  updateTmsShift,
  setDashboardScale,
  setSignalView,
  newChartTab,
  toggleChartPosition,
  setChartTabData,
  setChartTabs,
  setChartTabStrategy,
  toggleOrdersPanel,
  setOrdersPanelTableSettings,
  setActiveChartTabTitle,
} from "./actions";

// tabItem = {
//   id: int,
//   url: str,
// }

const defaultTerminalSettings = {
  chartShowPosition: true,
  openOnNewWindow: true,
  showOrdersPanel: true,
  ordersPanelTableSettings: {},
};

export default combineReducers({
  layout: handleActions(
    {
      [toggleLayoutSettings]: (state, action) => action.payload,
    },
    { settingsIndex: 1, leftPanel: true, dashboardPanel: true }
  ),
  dashboardScale: handleActions(
    {
      [setDashboardScale]: (state, action) => (action.payload ? action.payload : state),
    },
    1
  ),
  tabs: handleActions(
    {
      [newTab]: (state, action) => {
        const maxId = state.reduce((acc, { id }) => (acc > id ? acc : id), 0);
        return [...state, { id: maxId + 1, url: action.payload }];
      },
      [setTabTitle]: (state, action) => {
        const tab = state.find(({ id }) => id === action.payload.id);
        if (!tab) return state;

        return [
          ...state.filter(({ id }) => id !== action.payload.id),
          { ...tab, title: action.payload.title },
        ];
      },
      [closeTab]: (state, action) => state.filter(({ id }) => id !== action.payload),
    },
    []
  ),
  chartTabs: handleActions(
    {
      [setActiveTab]: (state) =>
        state.map((tab) => ({
          ...tab,
          title: tab.symbol,
        })),
      [newChartTab]: (state, action) => {
        state.forEach((tab) => {
          tab.title = tab.symbol;
        });

        return [...state, { title: action.payload.symbol || "tab", ...action.payload }];
      },
      [setActiveChartTabTitle]: (state, action) => {
        return state.map((tab) => {
          if (tab.id === action.payload.id) {
            return {
              ...tab,
              title: action.payload.title,
            };
          }

          return tab;
        });
      },
      [setChartTabData]: (state, action) => {
        return state.map((tab) => {
          if (tab.id === action.payload.id) {
            return {
              id: tab.id,
              title: action.payload.data.symbol || "tab",
              ...action.payload.data,
            };
          }

          return tab;
        });
      },
      [setChartTabStrategy]: (state, action) => {
        return state.map((tab) => {
          if (tab.id === action.payload.id) {
            return {
              ...tab,
              ...action.payload.data,
            };
          }

          return tab;
        });
      },
      [setChartTabs]: (state, action) =>
        action.payload.map((tab) => ({
          ...tab,
          title: tab.symbol || "tab",
        })),
      [closeTab]: (state, action) => state.filter(({ id }) => id !== action.payload),
    },
    []
  ),
  terminalSettings: handleActions(
    {
      [toggleChartPosition]: (state) => ({
        ...state,
        chartShowPosition: !state.chartShowPosition,
      }),
      [toggleOrdersPanel]: (state) => ({
        ...state,
        showOrdersPanel: !state.showOrdersPanel,
      }),
      [setOrdersPanelTableSettings]: (state, action) => ({
        ...state,
        ordersPanelTableSettings: action.payload,
      }),
    },
    defaultTerminalSettings
  ),
  activeTabId: handleActions(
    {
      [setActiveTab]: (state, action) => action.payload,
    },
    "brokerTab"
  ),
  activeSignalsType: handleActions(
    {
      [setActiveSignalsType]: (state, action) => action.payload,
    },
    { id: "10", term: null }
  ),
  activeBroker: handleActions(
    {
      [setActiveBroker]: (state, action) => action.payload,
    },
    null
  ),
  tms: handleActions(
    {
      [updateTms]: (state, action) => action.payload,
    },
    Date.now()
  ),
  tmsShift: handleActions(
    {
      [updateTms]: () => 0,
      [updateTmsShift]: (state, action) => state + 1,
    },
    0
  ),
  signalView: handleActions(
    {
      [setSignalView]: (state, action) => action.payload,
    },
    1
  ),
});
