import React from "react";
import styled from "styled-components";

import Signal from "../Signal";

const Back = (props) => {
  if (!props.data) return null;
  return (
    <Wrapper>
      <Signal {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(-180deg);
`;

export default Back;
