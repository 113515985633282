import React, { useState } from "react";
import styled from "styled-components";

import Front from "./Front";
import Back from "./Back";

const AdaptiveSignal = ({ frontSignal, backSignal, ...rest }) => {
  const [rolled, setRolled] = useState(true);
  const [flipped, setFlipped] = useState(false);

  if (!frontSignal && !backSignal) return null;

  const handleRollClicked = () => {
    setRolled((prev) => !prev);
  };

  const handleReverseClicked = () => {
    setFlipped((prev) => !prev);
  };

  return (
    <AdaptiveContainer rolled={rolled} flipped={flipped}>
      {frontSignal && (
        <Front
          data={frontSignal}
          {...rest}
          adaptiveRolled={rolled}
          onRolled={handleRollClicked}
          onReverse={handleReverseClicked}
        />
      )}
      {backSignal && (
        <Back
          data={backSignal}
          {...rest}
          adaptiveRolled={rolled}
          onRolled={handleRollClicked}
          onReverse={handleReverseClicked}
        />
      )}
    </AdaptiveContainer>
  );
};

const AdaptiveContainer = styled.div`
  //position: relative;
  min-height: ${({ rolled }) => (rolled ? "120px" : "222px")};
  transform-style: preserve-3d;
  transition: 0.5s;
  transform: ${({ flipped }) => (flipped ? "rotateY(180deg)" : "rotateY(0deg)")};
`;

export default AdaptiveSignal;
