import { createAction } from "redux-actions";

const PREFIX = "PROVIDERS";

export const setProviders = createAction(`${PREFIX}/SET_PROVIDERS`);
export const setActiveProvider = createAction(`${PREFIX}/SET_ACTIVE`);
export const setSelectData = createAction(`${PREFIX}/SET_SELECT_DATA`);
export const setOrdersPositionSize = createAction(`${PREFIX}/SET_ORDERS_POSITION_SIZE`);
export const toggleQuickBuy = createAction(`${PREFIX}/TOGGLE_QUICK_BUY`);
export const setConnectionStatus = createAction(`${PREFIX}/SET_CONNECTION_STATUS`);

export const setLoadingOrderPlacement = createAction(`${PREFIX}/SET_LOADING_ORDER_PLACEMENT`);

export const setOpenOrders = createAction(`${PREFIX}/SET_OPEN_ORDERS`);

export const setConnectionError = createAction(`${PREFIX}/SET_CONNECTION_ERROR`);
export const setApplySettingsError = createAction(`${PREFIX}/SET_APPLY_SETTINGS_ERROR`);

export const createSelectOption = createAction(`${PREFIX}/CREATE_SELECT_OPTION`);
export const setSelectOption = createAction(`${PREFIX}/SET_SELECT_OPTION`);

export const setAccountData = createAction(`${PREFIX}/SET_ACCOUNT_DATA`);

export const setPositionPnl = createAction(`${PREFIX}/SET_POSITION_PNL`);

export const setOrdersHistory = createAction(`${PREFIX}/SET_ORDERS_HISTORY`);
export const setIsOrdersHistoryLoading = createAction(`${PREFIX}/SET_IS_ORDER_HISTORY_LOADING`);

export const setProviderIntegrations = createAction(`${PREFIX}/SET_PROVIDER_INTEGRATIONS`);
export const setProviderToken = createAction(`${PREFIX}/SET_PROVIDER_TOKEN`);
