import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getBrokersList } from "../../../../modules/initial";
import {
  getCustomBrokersList,
  isClientPRO,
} from "../../../../modules/mainSettings";
import { getActiveBroker, setActiveBroker } from "../../../../modules/client";

import View from "./View";

class BrokerSettings extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    availableBrokersList: PropTypes.object.isRequired,
    activeBroker: PropTypes.string,
    isPro: PropTypes.bool.isRequired,
  };

  handleSelectBroker = (name) => {
    this.props.setActiveBroker(name);
  };

  render() {
    const {
      availableBrokersList,
      isActive,
      activeBroker,
      isPro
    } = this.props;

    const customBrokers = isPro ? this.props.customBrokers : [];

    return (
      <View
        isActive={isActive}
        list={availableBrokersList}
        customList={customBrokers}
        activeItem={activeBroker}
        onClick={this.handleSelectBroker}
      />
    );
  }
}

export default connect(
  (state) => ({
    availableBrokersList: getBrokersList(state),
    activeBroker: getActiveBroker(state),
    customBrokers: getCustomBrokersList(state),
    isPro: isClientPRO(state),
  }),
  { setActiveBroker }
)(BrokerSettings);
