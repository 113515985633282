import { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import Meta from "./Meta";
import SignalMenu from "./SignalMenu";
import { ReactComponent as GraphButtonIcon } from "./assets/graph-icon-button.svg";
import { ReactComponent as GraphLeftIcon } from "./assets/graph-left-icon.svg";
import { ReactComponent as PieIcon } from "./assets/pie-icon.svg";
import { ReactComponent as RollIcon } from "./assets/roll-icon.svg";
import { ReactComponent as MagicIcon } from "./assets/magic-icon.svg";
import { ReactComponent as ReverseIcon } from "./assets/reverse-icon.svg";
import signal0 from "./assets/signal-0.svg";
import signal1 from "./assets/signal-1.svg";
import signal2 from "./assets/signal-2.svg";
import signal3 from "./assets/signal-3.svg";
import signal4 from "./assets/signal-4.svg";
import signal5 from "./assets/signal-5.svg";
import ClassicVariants from "./ClassicVariants";

const buttonSize = "21px";

const powerIcons = {
  0: signal0,
  1: signal1,
  2: signal2,
  3: signal3,
  4: signal4,
  5: signal5,
  6: signal5, // нет такого ассета
  blocked: signal0,
};

const View = (props) => {
  const {
    id,
    showFull,
    power,
    symbol,
    hm,
    period,
    signal,
    stop_loss,
    take_profit,
    isAdaptive,
    adaptiveRolled,
    orderStrategyList,
    orderStrategyId,
    isLoadingOrderPlacement,
    price,
    s1,
    s2,
    s3,
    s4,
    s5,
    s6,
    onSetSymbolClicked,
    onOpenChartsClicked,
    onPlaceOrderClicked,
    onChangeStrategy,
    onReverse,
    isStatic, // для отображения в окне настроек Signal View
  } = props;
  const [rolled, setRolled] = useState(isAdaptive ? adaptiveRolled : true);

  useEffect(() => {
    if (isAdaptive) {
      setRolled(adaptiveRolled);
    }
  }, [isAdaptive, adaptiveRolled]);

  const orderStrategyProfitList = [s1, s2, s3, s4, s5, s6].map((item) => {
    if (isNaN(parseFloat(item))) {
      return "-";
    }
    return parseFloat(item).toFixed(1);
  });

  const handlePlaceOrder = ({ volume }) => {
    onPlaceOrderClicked({ volume, take_profit, stop_loss });
  };

  const handleRollClicked = () => {
    if (isStatic) return;

    setRolled((prev) => !prev);
    // метод, который нужно передать из компонента AdaptiveSignal
    const { onRolled } = props;
    if (onRolled) {
      onRolled();
    }
  };

  const handleReverseClicked = () => {
    onReverse();
  };

  const handleMagicClicked = () => {
    if (isStatic) return;
    onOpenChartsClicked(period, symbol);
    onSetSymbolClicked(period, symbol);
    setRolled((prev) => !prev);
    // метод, который нужно передать из компонента AdaptiveSignal
    const { onRolled } = props;
    if (onRolled) {
      onRolled();
    }
  };

  return (
    <Wrapper rolled={rolled}>
      <Container rolled={rolled}>
        <DataContainer>
          <SymbolPowerContainer>
            <SymbolContainer>
              <SymbolLabel showFull={props.showFull}>{symbol}</SymbolLabel>
              <ActionButtonsContainer showFull={props.showFull}>
                {isAdaptive && (
                  <ReverseIconButton
                    fill="#FFF"
                    isstatic={isStatic ? "true" : "false"}
                    width={buttonSize}
                    height={buttonSize}
                    onClick={handleReverseClicked}
                  />
                )}
                {isAdaptive ? (
                  <GraphIconButton
                    isstatic={isStatic ? "true" : "false"}
                    fill="#FFF"
                    width={buttonSize}
                    height={buttonSize}
                    onClick={() => onOpenChartsClicked(period, symbol, price)}
                  />
                ) : (
                  <GraphLeftIconButton
                    isstatic={isStatic ? "true" : "false"}
                    fill="#FFF"
                    width={buttonSize}
                    height={buttonSize}
                    onClick={() => onOpenChartsClicked(period, symbol, price)}
                  />
                )}
                <RollIconButton
                  active={!rolled}
                  isstatic={isStatic ? "true" : "false"}
                  fill="#FFF"
                  width={buttonSize}
                  height={buttonSize}
                  onClick={handleRollClicked}
                />
                <PieIconButton
                  isstatic={isStatic ? "true" : "false"}
                  fill="#FFF"
                  width={buttonSize}
                  height={buttonSize}
                  onClick={() => onSetSymbolClicked(period, symbol)}
                />
                <MagicIconButton
                  isstatic={isStatic ? "true" : "false"}
                  fill="#FFF"
                  width={buttonSize}
                  height={buttonSize}
                  onClick={handleMagicClicked}
                />
              </ActionButtonsContainer>
            </SymbolContainer>
            <PowerContainer {...{ showFull, power }} />
          </SymbolPowerContainer>
          <ClassicVariants {...props} />
          <Meta signalTms={props.timestamp} algName={props.alg} period={props.period} />
        </DataContainer>
        <HeatMapContainer>
          {["m1", "m5", "m15", "m30", "h1", "h4"].map((item) => (
            <HeatMapItem key={item} value={hm[item]} disabled={!showFull} />
          ))}
        </HeatMapContainer>
      </Container>
      <SignalMenu
        active={!rolled}
        showFull={showFull}
        side={signal}
        signalId={id}
        orderStrategyList={orderStrategyList}
        orderStrategyProfitList={orderStrategyProfitList}
        orderStrategyId={orderStrategyId}
        onChangeStrategy={onChangeStrategy}
        isLoadingOrderPlacement={isLoadingOrderPlacement}
        onPlaceOrder={handlePlaceOrder}
      />
    </Wrapper>
  );
};

export default View;

const gradientColor = (val) => {
  const colors = {
    5: "#49d147",
    4: "#39f711",
    3: "#64f711",
    2: "#99f711",
    1: "#bdf725",
    0: "#e0f465",
    "-0": "#f2df5e",
    "-1": "#f2b34c",
    "-2": "#f1904a",
    "-3": "#f16139",
    "-4": "#ff4a39",
    "-5": "#f1000e",
  };

  const div = val - 50;
  const perc = (100 * div) / 50;
  const index = Math.floor(perc / 10);
  let rez = index.toString();

  if (index === 0 && div < 0) rez = "-0";
  if (index > 5) rez = "5";
  if (index < -5) rez = "-5";

  return colors[`${rez}`];
};

const Wrapper = styled.div`
  position: relative;
  margin: ${(props) => (props.rolled ? "0 0 -20px" : "0 0 5px")};
  transition: all 0.5s;
`;

const Container = styled.div`
  position: relative;
  background: #1e2432;
  border-radius: ${(props) => (props.rolled ? "3px" : "3px 3px 0 0 ")};
  padding: 8px 0 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`;

const HeatMapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 3px 3px 1px;
`;

const HeatMapItem = styled.div`
  width: 32.02px;
  height: 2px;
  border-radius: 2px;
  margin: 0 2px;
  opacity: 0.9;
  ${(props) =>
    css`
      background-color: ${props.disabled ? "#91949B" : gradientColor(props.value)};
    `};
`;

const DataContainer = styled.div`
  margin: 0 12px 0 12px;
`;

const BaseIconButton = (isStatic, active = false) => css`
  background-color: rgba(120, 120, 120, 0.3);
  height: 100%;
  cursor: pointer;
  opacity: 0;
  margin-right: 1px;

  g {
    transition: all 0s;
  }

  ${Container}:hover & {
    opacity: 1;
  }

  &:hover {
    background-color: rgba(120, 120, 120, 0.8);
    path {
      & + path {
        fill: white;
        fill-opacity: 1;
      }
    }
    g {
      fill: white;
      fill-opacity: 1;
      transition: all 0s;
    }
  }

  ${active
    ? css`
        path {
          & + path {
            fill: white;
            fill-opacity: 1;
          }
        }
      `
    : ""}

  ${isStatic
    ? css`
        opacity: 1;
        background-color: rgba(120, 120, 120, 0.8);
      `
    : ""}
`;

const ReverseIconButton = styled(ReverseIcon)`
  border-radius: 6px 0 0 6px;
  ${(props) => BaseIconButton(props.isstatic === "true", props.active)}
`;

const GraphLeftIconButton = styled(GraphLeftIcon)`
  border-radius: 6px 0 0 6px;
  ${(props) => BaseIconButton(props.isstatic === "true", props.active)}
`;

const GraphIconButton = styled(GraphButtonIcon)`
  ${(props) => BaseIconButton(props.isstatic === "true")}
`;

const RollIconButton = styled(RollIcon)`
  ${(props) => BaseIconButton(props.isstatic === "true", props.active)}
`;

const PieIconButton = styled(PieIcon)`
  /* border-radius: 0 3px 3px 0; */
  ${(props) => BaseIconButton(props.isstatic === "true")}
`;

const MagicIconButton = styled(MagicIcon)`
  border-radius: 0 6px 6px 0;
  ${(props) => BaseIconButton(props.isstatic === "true")}
`;

const SymbolPowerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7px;
`;

const SymbolContainer = styled.div`
  position: relative;
  display: flex;
  align-items: baseline;
  flex: 0 1 55%;
`;

const SymbolLabel = styled.div`
  color: ${(props) => (props.showFull ? "#fff" : "#8C8E95")};
  font-size: 21px;
  font-weight: bold;
  margin-right: 5px;
`;

const ActionButtonsContainer = styled.div`
  position: absolute;
  left: 0px;
  top: -5px;
  align-items: center;
  background-color: #1e2432;
  padding: 1px 0;
  display: none;
  border-radius: 3px;

  ${(props) => {
    if (props.showFull)
      return css`
        ${SymbolContainer}:hover & {
          display: flex;
          z-index: 5;
        }
      `;
  }}
`;

const PowerContainer = styled.div`
  width: 32px;
  height: 13px;
  ${(props) => {
    const iconSource = powerIcons[props.showFull ? props.power : "blocked"];
    return css`
      background-image: url(${iconSource});
    `;
  }}
`;
