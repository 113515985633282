import React from "react";
import packageJson from "../../../../../package.json";
import params from "../../../../config"
import styled from "styled-components";
import logoVfx from "./assets/logo-vfxalert.svg"
import logoXsignals from "./assets/logo-xsignals.svg"

const View = ({ isActive, openLink, disclaimer, agreement, policy }) => {
  const links = [
    {
      url: disclaimer,
      title: "Disclaimer Warning",
    },
    {
      url: agreement,
      title: "Terms of use",
    },
    {
      url: policy,
      title: "Privacy policy",
    },
  ];

  return (
    <>
      {/* <!-- Модальное окно с информацией о версии --> */}
      {/* <!-- Изначально окно скрыто. При добавлении класса active, окно становится видимым --> */}
      {/* <div className={`modal_overlay ${isActive ? "active" : ""}`}> */}
      <div
        className={`modal_content modal_content1 ${isActive ? "active" : ""}`}
      >
        {/* <div className="version"> */}
        <div style={{width: "100%", paddingTop: 100}}>
          {/* <div className="modal_close_btn" onClick={handleClick}></div> */}
          {/* <div className="version_logo_new"></div> */}
          <div className="version_title">
            {params.appName}
          </div>
          <div className="version_title">
            Version {packageJson.version} alpha Build {packageJson.hash} for {window.platform ?? 'browser'}
          </div>
          <div className="version_title">
            Copyright {new Date().getFullYear()} X-Systems Ltd. All rights reserved.
          </div>
          {/* <div className="version_title version_title_last"> */}
            {/* License - PRO License Valid - 2020-04-07+09:38:33 */}
          {/* </div> */}
          {/* <div className="version_text_box"> */}
            {/* <div className="version_text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque nisl ipsum, lobortis non nibh id, posuere tempor
              quam. Praesent malesuada pharetra diam vel condimentum.
              Pellentesque egestas vel ex nec iaculis. Praesent congue eros ut
              magna semper vehicula. Cras fringilla eros at diam vulputate
              faucibus. Vivamus ex eros, ultricies ac porta vel, pharetra ut
              ipsum. Suspendisse molestie convallis mauris eget vulputate.
              Maecenas nec iaculis odio. Nam quis finibus diam. Pellentesque
              suscipit sodales ligula, sit amet vestibulum libero laoreet eget.
              Nam eu tempus magna. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Pellentesque nisl ipsum, lobortis non nibh id,
              posuere tempor quam. Praesent malesuada pharetra diam vel
              condimentum. Pellentesque egestas vel ex nec iaculis. Praesent
              congue eros ut magna semper vehicula. Cras fringilla eros at diam
              vulputate faucibus. Vivamus ex eros, ultricies ac porta vel,
              pharetra ut ipsum. Suspendisse molestie convallis mauris eget
              vulputate. Maecenas nec iaculis odio. Nam quis finibus diam.
              Pellentesque suscipit sodales ligula, sit amet vestibulum libero
              laoreet eget. Nam eu tempus magna.
            </div> */}
          {/* </div> */}
          <div className="version_box">
            {links.map(({ url, title }, key) => (
              <div
                key={key}
                className="version_box_item"
                onClick={() => {
                  openLink(url);
                }}
              >
                {title}
              </div>
            ))}
            <Logo src={params.appEnvType !== "classic" ? logoVfx : logoXsignals}/>
          </div>
        </div>
      </div>
      {/* <!--Модальные окна с информацией о версии конец-- > */}
    </>
  );
};

export default View;

const Logo = styled.img`
  width: 62px;
  height: 62px;
  margin-left: 150px;
`;