import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchInitialSettings, isInitialLoaded, toggleInitialPopup } from "./modules/initial";
import { setLicenseKey } from "./modules/mainSettings";
import { invalidateStorage, getStorageVersion } from "./modules/storageVersion";
import Layout from "./Components/Layout";
import isElectron from "is-electron";
import Cookies from "js-cookie";
import { NotificationContainer } from "react-notifications";
import Metrika from "./Components/Metrika";
import params from "./config";

const { actualStorageVersion } = params;

class App extends Component {
  componentWillMount() {
    const { dispatch, storageVersion } = this.props;
    if (storageVersion !== actualStorageVersion) {
      dispatch(invalidateStorage());
      return;
    }
    if (isElectron()) {
      dispatch(toggleInitialPopup(true));
    }
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const cookieLkey = Cookies.get("lkey");
    if (!isElectron() && cookieLkey) dispatch(setLicenseKey({ lkey: cookieLkey }));

    dispatch(fetchInitialSettings());
  }

  render() {
    if (this.props.storageVersion !== actualStorageVersion) {
      return null;
    }

    return (
      <>
        <Layout />
        <NotificationContainer />
        <Metrika />
      </>
    );
  }
}

export default connect((state) => ({
  isInitialLoaded: isInitialLoaded(state),
  storageVersion: getStorageVersion(state),
}))(App);
