import { getSavedChartData } from "../charts";

export const getProviders = (state) => state.providers.list;
export const getActiveProvider = (state) => state.providers.list.find((provider) => provider.isActive);
export const getConnectionStatus = (state) => {
  const active = getActiveProvider(state);

  return active?.connectionStatus || false;
};

export const getOrdersPositionSize = (state) => {
  const active = getActiveProvider(state);
  const ordersPosSize = active?.ordersPositionSize;

  if (ordersPosSize) return ordersPosSize;

  return active?.fields?.ordersPositionSize?.defaults;
};

export const getSelectData = (state) => {
  const active = getActiveProvider(state);

  if (active?.selectData?.length) {
    return active?.selectData;
  }

  return active?.fields?.select?.defaults;
};

export const getSelectCreatableStatus = (state) => {
  const active = getActiveProvider(state);

  return active?.fields?.select?.creatable;
};

export const getSelectedOption = (state) => {
  const active = getActiveProvider(state);

  const selectData = active?.selectData;

  return selectData ? selectData.find((option) => option.selected) || selectData[0] : null;
};

export const getProviderOpenOrders = (state) => {
  const active = getActiveProvider(state);

  return (
    active?.openOrders || {
      positions: [],
      orders: [],
    }
  );
};

export const getIsLoadingOrderPlacement = (state) => state.providers.isLoadingOrderPlacement;

export const getConnectionError = (state) => state.providers.connectionError;
export const getApplySettingsError = (state) => state.providers.applySettingsError;
export const getQuickBuyStatus = (state) => {
  const active = getActiveProvider(state);

  if (active?.quickBuyStatus) {
    return active.quickBuyStatus;
  }

  return false;
};

export const getProviderIntegrations = (state) => state.providers.integrations;

export const getProviderToken = (state, provider = undefined) => {
  const providerIntegrations = getProviderIntegrations(state);

  if (provider) {
    return providerIntegrations[provider.toLowerCase()] || null;
  }

  const active = getActiveProvider(state);

  if (active) {
    if (providerIntegrations) {
      return providerIntegrations[active.name.toLowerCase()];
    }
  }

  return null;
};

export const getAccountBalance = (state) => {
  const active = getActiveProvider(state);

  if (active && active.account) {
    return active.account;
  }

  return {
    currency: "",
    balance: 0,
  };
};

export const getAllProviderContracts = (state) => {
  const active = getActiveProvider(state);

  return active?.account.contracts || [];
};

export const getProviderContract = (state, symbol) => {
  const active = getActiveProvider(state);

  return (
    active?.account?.contracts?.find(
      (contract) => `${contract.marginCurrency}${contract.priceCurrency}` === symbol
    ) || null
  );
};

export const getOrdersHistory = (state) => {
  const active = getActiveProvider(state);

  return (
    active?.ordersHistory?.map((order) => {
      let userData = null;
      let savedChartData = null;

      try {
        userData = JSON.parse(order.userData);
      } catch (e) {}

      if (userData?.chartPlayerId) {
        savedChartData = getSavedChartData(state, order.userData);
      }

      let chartPlayerData = savedChartData
        ? {
            ...savedChartData,
            chartData: {
              ...savedChartData.chartData,
              side: order.side,
              signalPrice: userData ? userData.signalPrice : null,
              openPrice: order.price,
              takeProfit: order.tpPrice ? parseFloat(`${order.tpPrice}`) : null,
              stopLoss: order.slPrice ? parseFloat(`${order.slPrice}`) : null,
              closeTime: order.closeTime,
            },
          }
        : {
            id: active.name,
            chartData: {
              interval: "5",
              fromTime: Math.floor(order.time / 1000),
              symbol: order.symbol,
              type: active.type,
              side: order.side,
              signalPrice: userData ? userData.signalPrice : null,
              openPrice: order.price,
              takeProfit: order.tpPrice ? parseFloat(`${order.tpPrice}`) : null,
              stopLoss: order.slPrice ? parseFloat(`${order.slPrice}`) : null,
              closeTime: order.closeTime,
            },
          };

      // if (active.type === "forex" && chartPlayerData.chartData.fromTime) {
      //   const weekInSeconds = 60 * 60 * 24 * 3;
      //   if (Math.floor(Date.now() / 1000) - chartPlayerData.chartData.fromTime > weekInSeconds) {
      //     chartPlayerData = null;
      //   }
      // }

      return {
        ...order,
        chartPlayerData,
      };
    }) || null
  );
};

export const getHowToConnectLink = (state) => {
  const active = getActiveProvider(state);

  return active?.how_to_url || null;
};

export const getIsOrderHistoryLoading = (state) => state.providers.isOrderHistoryLoading;
