import socketIO from "socket.io-client";

export class WSConnection {
  constructor(settings = {}) {
    const { host, channel, assets } = settings;
    console.log("init socket url", host);

    this.socket = socketIO(`${host}`);
    this.socket.emit("user:join", { appId: channel, client: { ...settings }, assets });

    this.socket.on("disconnect", () => {
      console.log("ws disconnected");
      document.location.reload();
    });
    this.socket.on("reconnect", () => {
      console.log("ws reconnected!");
    });
  }

  subscribe(event, handler) {
    this.socket.on(event, handler);
    return this;
  }

  emit(event, args = null) {
    this.socket.emit(event, args);
  }

  removeAllListeners() {
    this.socket.removeAllListeners();
  }

  disconnect() {
    this.socket.disconnect();
  }
}
