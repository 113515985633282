import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import iconSignal1 from "../../../../../base/assets/signal/signal-1.svg";
import iconSignal2 from "../../../../../base/assets/signal/signal-2.svg";
import iconSignal3 from "../../../../../base/assets/signal/signal-3.svg";
import iconSignal4 from "../../../../../base/assets/signal/signal-4.svg";
import iconSignal5 from "../../../../../base/assets/signal/signal-5.svg";
import iconSignal from "../../../../../base/assets/signal/signal.svg";
import CheckBox from "../../../../../base/form/CheckBox";
import ErrorMessage from "../../../../../base/form/ErrorMessage";
import Input from "../../../../../base/form/Input";
import Select from "../../../../../base/form/Select";
import SelectPreset from "./SelectPreset";

SwiperCore.use([Pagination, Navigation]);

const signalPowerIcons = [iconSignal, iconSignal1, iconSignal2, iconSignal3, iconSignal4, iconSignal5];

const View = ({
  onAssetChange,
  currentAsset,
  availableFilters: { assets, alg },
  presetError,
  presets,
  periods,
  onItemChange,
  onCreatePreset,
  onDeletePreset,
  onChangePreset,
  onChangeAssetsSelection,
}) => {
  const [presetName, setPresetName] = useState("");
  const [navigationOffset, setNavigationOffset] = useState(0);
  const nextArrow = useRef(null);
  const prevArrow = useRef(null);

  useEffect(() => {
    let defaultPresetValue;
    if (presets.length) {
      defaultPresetValue = presets.find(
        ({ presetName, id }) => presetName === currentAsset.presetName && id === currentAsset.id
      );
    }
    if (!defaultPresetValue) {
      defaultPresetValue = { presetName: "Preset list" };
    }
    setPresetValue(defaultPresetValue);
  }, [currentAsset.id, currentAsset.presetName, presets]);

  useEffect(() => {
    const bulletsCount = document.querySelectorAll(".swiper-pagination .swiper-pagination-bullet").length;

    setNavigationOffset((bulletsCount / 2) * 16 + 30);
  }, [setNavigationOffset]);

  const [presetValue, setPresetValue] = useState();

  return (
    <Container>
      <LeftColumn>
        <StyledSelect
          onChange={onAssetChange}
          value={{
            label: currentAsset.id?.toUpperCase(),
            value: currentAsset.id,
          }}
          options={Object.entries(assets).map(([name, items]) => ({
            label: name.toUpperCase(),
            value: name,
          }))}
          background={"#454C63"}
        />

        <div>
          <Input
            placeholder="Name"
            value={presetName}
            onChange={(e) => setPresetName(e.target.value)}
            buttonText="Create preset"
            onClick={() => {
              onCreatePreset(presetName);
              setPresetName("");
            }}
          />
          {!!presetError && <ErrorMessage errorText="Preset with the same name already exists" />}
        </div>

        <SelectPreset
          value={presetValue}
          options={presets}
          getOptionValue={(preset) => preset.presetName}
          getOptionLabel={(preset) => preset.presetName}
          background={"#454C63"}
          onDeleteOption={(presetName) => {
            onDeletePreset(presetName);
            setPresetValue({ presetName: "Preset List" });
          }}
          onChange={(preset) => onChangePreset(preset)}
        />
      </LeftColumn>
      <RightColumn>
        <AssetsColumn>
          <ColumnTitle>
            Assets
            <SelectButtonsWrapper>
              <SelectButton onClick={onChangeAssetsSelection("select")}>Select all</SelectButton>
              <SelectButton onClick={onChangeAssetsSelection("deselect")}>Deselect all</SelectButton>
            </SelectButtonsWrapper>
          </ColumnTitle>
          <div>
            {assets[currentAsset.id].length > 9 ? (
              <>
                <StyledSwiper
                  shortSwipes={false}
                  slidesPerView={3}
                  slidesPerColumn={3}
                  slidesPerColumnFill="row"
                  pagination
                  navigation={
                    nextArrow.current && prevArrow.current
                      ? {
                          prevEl: prevArrow.current,
                          nextEl: nextArrow.current,
                        }
                      : false
                  }
                >
                  {assets[currentAsset.id].map((name) => (
                    <SwiperSlide key={`${currentAsset.id}_${name}`}>
                      <SymbolsItemWrapper>
                        <CheckBox
                          label={name.toUpperCase()}
                          value={currentAsset.selected.indexOf(name) > -1}
                          onChange={onItemChange}
                          name={`selected_${name}`}
                        />
                      </SymbolsItemWrapper>
                    </SwiperSlide>
                  ))}
                </StyledSwiper>
                <SwiperNavigationWrapper>
                  <PrevArrow offset={navigationOffset} ref={prevArrow} />
                  <NextArrow offset={navigationOffset} ref={nextArrow} />
                </SwiperNavigationWrapper>
              </>
            ) : (
              <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 40 }}>
                {assets[currentAsset.id].map((name) => (
                  <SymbolsItemWrapper key={`${currentAsset.id}_${name}`}>
                    <CheckBox
                      label={name.toUpperCase()}
                      value={currentAsset.selected.indexOf(name) > -1}
                      onChange={onItemChange}
                      name={`selected_${name}`}
                    />
                  </SymbolsItemWrapper>
                ))}
              </div>
            )}
          </div>
        </AssetsColumn>
        <Row>
          <BottomColumn>
            <ColumnTitle>Period</ColumnTitle>
            {
              <>
                {periods?.map((period) => (
                  <CheckboxItemWrapper key={period.value}>
                    <CheckBox
                      label={period.title}
                      id={`expiration_${period.value}`}
                      value={!!currentAsset.expiration.find((item) => item.value === period.value)}
                      onChange={onItemChange}
                      name={`expiration_${JSON.stringify(period)}`}
                    />
                  </CheckboxItemWrapper>
                ))}
              </>
            }
          </BottomColumn>
          <BottomColumn>
            <ColumnTitle>Power</ColumnTitle>
            {[...Array(6).keys()].map((power) => (
              <CheckboxItemWrapper key={power}>
                <CheckBox
                  renderLable={() => <PowerLabel icon={signalPowerIcons[power]} />}
                  id={`asset_power_${power}`}
                  className="asset_check"
                  value={currentAsset.power.indexOf(power) > -1}
                  onChange={onItemChange}
                  name={`power_${power}`}
                />
              </CheckboxItemWrapper>
            ))}
          </BottomColumn>
          <BottomColumn>
            <ColumnTitle>Algorithm</ColumnTitle>
            {Object.entries(alg).map(([id, name]) => (
              <CheckboxItemWrapper key={id}>
                <CheckBox
                  label={name}
                  id={`alg_${id}`}
                  className="asset_check"
                  value={currentAsset.algorithm.indexOf(id) > -1}
                  onChange={onItemChange}
                  name={`algorithm_${id}`}
                />
              </CheckboxItemWrapper>
            ))}
          </BottomColumn>
        </Row>
      </RightColumn>
    </Container>
  );
};

const Container = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const LeftColumn = styled(Column)`
  flex: 0 0 42%;
  margin-right: 30px;
  & > * {
    margin-bottom: 40px;
  }
`;

const RightColumn = styled(Column)`
  flex: 0 0 52%;
  overflow-x: hidden;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  margin-top: 15px;
`;

const BottomColumn = styled.div`
  flex: 0 0 141px;
`;

const StyledSelect = styled(Select)`
  margin-bottom: 40px;
`;

const StyledSwiper = styled(Swiper)`
  padding-bottom: 30px;
`;

const AssetsColumn = styled(Column)`
  position: relative;
  width: 99%;
`;

const ColumnTitle = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
`;

const PowerLabel = styled.div`
  width: 32px;
  height: 13px;
  padding-right: 35px;
  background-repeat: no-repeat;
  background-position-x: right;
  ${(props) =>
    css`
      background-image: url(${props.icon});
    `}
`;

const SymbolsItemWrapper = styled.div`
  margin-bottom: 15px;
  flex: 0 0 33%;
`;

const CheckboxItemWrapper = styled.div`
  margin-bottom: 15px;
`;

const SwiperNavigationWrapper = styled.div`
  position: relative;
  bottom: 17px;
  left: 50%;
  width: 0%;
`;

const SelectButtonsWrapper = styled.div`
  display: inline-block;
  margin-left: 15px;
`;

const SelectButton = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin: 0 0 0 10px;
  &:hover {
    cursor: pointer;
  }
`;

const PrevArrow = styled.span`
  display: ${({ offset }) => (offset ? "inline" : "none")};
  position: absolute;
  top: 0;
  left: ${({ offset }) => -offset + "px"};
  width: 10px;
  height: 18px;
  transform: translateY(-50%);
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
  }
  &::before {
    top: 5px;
    transform: rotate(-45deg);
  }
  &::after {
    bottom: 5px;
    transform: rotate(45deg);
  }
  &:hover {
    cursor: pointer;
  }
`;

const NextArrow = styled.span`
  display: ${({ offset }) => (offset ? "inline" : "none")};
  position: absolute;
  top: 0;
  right: ${({ offset }) => -offset + "px"};
  width: 10px;
  height: 18px;
  transform: translateY(-50%);
  z-index: 100;
  &::before,
  &::after {
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
  }
  &::before {
    top: 5px;
    transform: rotate(45deg);
  }
  &::after {
    bottom: 5px;
    transform: rotate(-45deg);
  }
  &:hover {
    cursor: pointer;
  }
`;

export default View;
