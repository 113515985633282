import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import ErrorBoundary from "./Components/ErrorBoundary";
import createStore from "./store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import "react-notifications/lib/notifications.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

const store = createStore();
const persistor = persistStore(store, { manualPersist: true }, () =>
  console.log("state persisted")
);

const Root = () => {
  useEffect(() => {
    persistor.persist();
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
