import { connect } from "react-redux";
import styled from "styled-components";
import DashboardSettingsItem from "./DashboardSettingsItem";
import {
  getDashboardItems,
  setEnabled,
  setSymbol,
  setVisibleSettings,
} from "../../../../../../modules/dashboard";
import { getFiltersConfig } from "../../../../../../modules/initial";

const DashboardsTab = (props) => {
  const { dashboardItems, filtersConfig: { assets }, setEnabled, setSymbol, setVisibleSettings } = props;

  return (
    <Container>
      {dashboardItems.length > 0 && dashboardItems.map((item, index) => (
        <DashboardSettingsItem key={index} {...item} {...{ assets, index, setEnabled, setSymbol, setVisibleSettings }}/>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 465px;
`;

export default connect(
  (state) => ({
    dashboardItems: getDashboardItems(state),
    filtersConfig: getFiltersConfig(state),
  }),
  { setEnabled, setVisibleSettings, setSymbol }
)(DashboardsTab);