import { ReactComponent as UpperPriceIcon } from "../assets/upper-price-icon.svg";
import { ReactComponent as LowerPriceIcon } from "../assets/lower-price-icon.svg";

import styled, { css } from "styled-components";
import { ReactComponent as LockIcon } from "../assets/signal-blocked.svg";

const colors = {
  1: "rgba(27,197,60,0.6)",
  "-1": "rgba(214,71,48,0.6)",
  blocked: "#54575E",
  // "-1": "#D64730",
};
const orderItemSize = "12px";

const View = (props) => {
  const { showFull, signal, price, take_profit, stop_loss } = props;

  const signalSide = showFull ? (
    <>
      <SignalLabel>{signal === 1 ? "BUY" : "SELL"}</SignalLabel>
      <SignalPrice>{price}</SignalPrice>
    </>
  ) : (
    <LockIcon />
  );

  return (
    <Container>
      <OrdersWrapper>
        <Order showFull={showFull} color={colors[showFull ? signal * 1 : "blocked"]}>
          <UpperPriceIcon width={orderItemSize} height={orderItemSize} />
          <OrderPrice>{signal === 1 ? take_profit : stop_loss}</OrderPrice>
        </Order>
        <Order showFull={showFull} color={colors[showFull ? signal * -1 : "blocked"]}>
          <LowerPriceIcon width={orderItemSize} height={orderItemSize} />
          <OrderPrice>{signal === -1 ? take_profit : stop_loss}</OrderPrice>
        </Order>
      </OrdersWrapper>
      <SignalWrapper color={{ 1: "#9FE6A0", "-1": "#FF665A" }[signal * 1]}>{signalSide}</SignalWrapper>

      {/*<SignalWrapper*/}
      {/*  color={{ "1": "#9FE6A0", "-1": "#FF665A" }[signal * 1]}>{signal === 1 ? "BUY" : "SELL"}</SignalWrapper>*/}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const OrdersWrapper = styled.div`
  width: 55%;
  height: 39px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  margin: 6px 0;
`;
const SignalWrapper = styled.div`
  opacity: 0.9;
  font-weight: bold;
  padding: 2px;
  ${(props) =>
    css`
      color: ${props.color};
    `}
`;
const SignalLabel = styled.div`
  font-size: 18px;
`;
const SignalPrice = styled.div`
  font-size: 12px;
`;
const Order = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 3px;
  padding: 3px 10px 2px 10px;
  opacity: 0.9;
  color: ${(props) => (props.showFull ? "white" : "#8C8E95")};
  svg {
    path {
      fill: ${(props) => (props.showFull ? "white" : "#8C8E95")};
    }
  }
  ${(props) =>
    css`
      background-color: ${props.color};
    `}
`;
const OrderPrice = styled.div`
  margin-left: 15px;
  color: #ffffff;
  font-weight: bold;
  font-size: ${orderItemSize};
`;

export default View;
