//process.env.NODE_ENV 'development' | 'production'

let params = {
  appEnvType: "classic",
  appName: "xSignals",
  apiHost: process.env.REACT_APP_API_HOST || "https://ds1.vfxalert.com",
  yandexMetrikaId: process.env.REACT_APP_YANDEX_METRIKA_ID || "",
  encryptStoreKey: process.env.REACT_APP_ENCRYPT_STORE_KEY || "very_secret_key",
  actualStorageVersion: 1,
};

export default params;
