import React, { Component } from "react";
import styled, { css } from "styled-components";
import { connect } from "react-redux";
import { getDashboardScale, getLayoutSettings, setDashboardScale } from "../../modules/client";
import PropTypes from "prop-types";
import LeftPanel from "../LeftPanel";
import WebTabsPanel from "../WebTabsPanel";
import DashboardPanel from "../DashboardPanel";
import Menu from "../Menu";
import PopupWindows from "../PopupWindows";
import SplitterLayout from "react-splitter-layout";
import { isAuth } from "../../modules/mainSettings";
import "./index.css";

const defaultLayout = (isAuth) => ({
  showTypeSettings: false,
  showBrokerSettings: false,
  showAppSettings: !isAuth,
  showVersionInfo: false,
});

class Layout extends Component {
  static propTypes = {
    leftPanel: PropTypes.bool.isRequired,
    dashboardPanel: PropTypes.bool.isRequired,
  };

  state = {
    ...defaultLayout(this.props.isAuth),
    isResizing: false,
    isDrag: false,
  };

  transformWrapper = React.createRef();

  handleResize = () => {
    if (!this.transformWrapper.current) return;
    const { height } = this.transformWrapper.current.getBoundingClientRect();
    const scale = height / 207;
    if (this.props.scale !== scale) this.props.setDashboardScale(scale);
  };

  menuButton = (stateIndex) => {
    const value = this.state[stateIndex];
    return {
      isActive: value,
      handleClick: ((stateIndex) => () => {
        const value = this.state[stateIndex];
        this.setState({
          ...defaultLayout(this.props.isAuth),
          [stateIndex]: !value,
        });
      })(stateIndex),
    };
  };

  render() {
    const { showBrokerSettings, isResizing } = this.state;
    const { leftPanel, dashboardPanel, scale } = this.props;
    const panelSize = 207 * (scale || 1);

    return (
      <>
        <section id="main">
          <Menu
            brokerSettings={this.menuButton("showBrokerSettings")}
            typeSettings={this.menuButton("showTypeSettings")}
            appSettings={this.menuButton("showAppSettings")}
            versionInfo={this.menuButton("showVersionInfo")}
          />
          <LeftPanel
            {...{
              showSignalsList: leftPanel,
              showBrokerSettings,
            }}
            typeSettings={this.menuButton("showTypeSettings")}
          />
          <div className="graph_wrap">
            <SplitterLayout
              vertical={true}
              secondaryInitialSize={panelSize}
              secondaryMinSize={150}
              onSecondaryPaneSizeChange={this.handleResize}
              onDragStart={() => {
                this.setState({ isResizing: true });
                this.handleResize();
              }}
              onDragEnd={() => {
                this.setState({ isResizing: false });
                this.handleResize();
              }}
            >
              <WebTabsPanelWrapper isResizing={this.state.isResizing}>
                <WebTabsPanel {...{ isResizing }} />
              </WebTabsPanelWrapper>
              {dashboardPanel && (
                <DashboardPanel
                  transformWrapper={this.transformWrapper}
                  scale={scale}
                  height={panelSize}
                  handleResize={this.handleResize}
                />
              )}
            </SplitterLayout>
          </div>
        </section>
        <PopupWindows
          appSettings={this.menuButton("showAppSettings")}
          versionInfo={this.menuButton("showVersionInfo")}
        />
      </>
    );
  }
}

const WebTabsPanelWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 10px;
  ${({ isResizing }) => {
    if (isResizing) {
      return css`
        pointer-events: none;
      `;
    }
  }}
`;

export default connect(
  (state) => ({
    ...getLayoutSettings(state),
    isAuth: isAuth(state),
    scale: getDashboardScale(state),
  }),
  { setDashboardScale }
)(Layout);
