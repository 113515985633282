import { getFiltersConfig } from "../initial";
import { connectQuotesSocket } from "../socket/quotesWS";
import { getBarsHistoryThunk } from "../providers";

export const PRICE_SCALE = 10000;

const configurationData = {
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: true,
  supported_resolutions: ["5", "15", "60", "240"],
};

let symbols = [];

export const getSymbolsForChartsThunk = () => (dispatch, getState) => {
  const { assets } = getFiltersConfig(getState());
  for (let key in assets) {
    assets[key].forEach((symbol) => {
      symbols.push({
        name: symbol,
        symbol,
        full_name: symbol,
        description: symbol,
        exchange: key === "crypto" ? "crypto" : "forex",
        ticker: symbol,
        pricescale: PRICE_SCALE,
        minmov: 1,
        session: key === "crypto" ? "24x7" : "0000-0000",
        type: key,
        timezone: "UTC",
        has_intraday: true,
        has_daily: true,
        has_weekly_and_monthly: true,
        currency_code: "USD",
      });
    });
  }
};

export const getDatafeed = (dispatch) => ({
  onReady: (callback) => {
    const interval = setInterval(() => {
      if (symbols.length) {
        callback(configurationData);
        clearInterval(interval);
      }
    }, 1000);
  },

  searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
    // const results = symbols.filter((symbolData) => symbolData.name.includes(userInput.toUpperCase()));
    // onResultReadyCallback(results);
  },

  resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
    const comps = symbolName.split(":");
    symbolName = (comps.length > 1 ? comps[1] : symbolName).toUpperCase();

    const symbol = symbols.find((i) => i.symbol === symbolName);
    return symbol
      ? setTimeout(() => onSymbolResolvedCallback(symbol), 0)
      : onResolveErrorCallback("[resolveSymbol]: symbol not found");
  },

  // get historical data for the symbol
  getBars: async (symbolInfo, interval, periodParams, onHistoryCallback, onErrorCallback) => {
    if (!periodParams.firstDataRequest) {
      onHistoryCallback([], { noData: true });
    }

    dispatch(getBarsHistoryThunk({ symbolInfo, interval, periodParams, onHistoryCallback, onErrorCallback }));
  },
  // subscription to real-time updates
  subscribeBars: (symbolInfo, interval, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => {
    onResetCacheNeededCallback();
    dispatch(connectQuotesSocket({ datafeedData: { symbolInfo, interval, onRealtimeCallback } }));
  },
  unsubscribeBars: (subscriberUID) => {},
  getServerTime: (callback) => {
    callback(Math.floor(Date.now() / 1000));
  },
});
