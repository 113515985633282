import { getAllSavedChartsData } from "./selectors";
import { setSavedCharts } from "./actions";

export const clearSavedChartsCacheThunk = () => (dispatch, getState) => {
  const state = getState();
  const savedCharts = getAllSavedChartsData(state);

  dispatch(
    setSavedCharts(savedCharts.filter((data) => Date.now() - data.createdAt < 1000 * 60 * 60 * 24 * 14))
  );
};
