import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getLicenseKey,
  getClientRate,
  setLicenseKey,
  removeLicenseKey,
  isClientPRO,
  getLicenseKeyError, isAuth,
} from "../../../../../../modules/mainSettings";

import View from "./View";
import { getGoproLink } from "../../../../../../modules/initial";

const defaultState = {
  newLicenseKey: "",
  login: "",
  password: ""
};

class MainTab extends Component {
  state = {
    ...defaultState,
  };

  handleInputChange = (e) => {
    const newState = {}
    if (e.target.name === 'newLicenseKey')
      this.setState({ ...defaultState, [e.target.name]: e.target.value });
      else
      this.setState({ [e.target.name]: e.target.value, newLicenseKey: "" });
  };

  handleApplyLicenseKey = () => {
    const { newLicenseKey, login, password } = this.state
    this.props.setLicenseKey({ lkey: newLicenseKey, login, password });
  };

  handleApplyDefault = (e) => { 
    localStorage.clear();
    window.location.reload()
  }

  render() {
    return (
      <View
        {...this.state}
        {...this.props}
        onFormFieldChange={this.handleInputChange}
        handleApplyLicenseKey={this.handleApplyLicenseKey}
        onApplyDefault={this.handleApplyDefault}
        removeLicenseKey={() => {
          this.setState({ newLicenseKey: "" });
          this.props.removeLicenseKey();
        }}
      />
    );
  }
}

export default connect(
  (state) => ({
    licenseKey: getLicenseKey(state),
    clientRate: getClientRate(state),
    isPro: isClientPRO(state),
    isAuth: isAuth(state),
    link: getGoproLink(state),
    isLicenseKeyError: getLicenseKeyError(state)
  }),
  {
    setLicenseKey,
    removeLicenseKey,
  }
)(MainTab);
