import React from "react";

const View = ({ id, url, title, isActive, onSelect, onClose }) => {
  return (
    //<!-- Если добавляется класс active то вкладка выглядит как активная. И крестик рядом с ней исчезает. Если без класса activ, вкладка выглядит поссивной. Крестик находится в теге div с классом graph_tab_close. --> */}
    <div className={`graph_tab_box ${isActive ? "active" : ""}`}>
      <p onClick={() => onSelect(id)} className="graph_tab">
        {title ? title.substring(0, 30) : ``}
      </p>
      {!isActive && onClose !== null && <div onClick={() => onClose(id)} className="graph_tab_close"></div>}
    </div>
  );
};

export default View;
