class ChartEventEmitter {
  listeners = {};

  on(event, handler) {
    if (!this.listeners.hasOwnProperty(event)) {
      this.listeners[event] = [];
    }

    this.listeners[event].push(handler);

    return this;
  }

  emit(event, ...rest) {
    if (!this.listeners.hasOwnProperty(event)) {
      return null;
    }

    for (const handler of this.listeners[event]) {
      handler.call(this, ...rest);
    }
  }

  removeAllListeners() {
    this.listeners = {};
  }
}

export default new ChartEventEmitter();
