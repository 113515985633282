import axios from "axios";
import isElectron from "is-electron";
import { confirmAlert } from "@xsystems0/xsignals-terminal";
import params from "../config";
import packageJson from "../../package.json";
import { NotificationManager } from "react-notifications";
import { accountRequestThunk } from "./providers";

const { appEnvType } = params;
const isDev = process.env.NODE_ENV === "development";
const devProviderServiceHost = process.env.REACT_APP_DEV_PROVIDER_SERVICE_HOST;

export const requestProviderClosePosition = async (
  dispatch,
  token,
  orderId,
  symbol,
  volume,
  closeDirection,
  providerOptions,
  providerName,
  host
) => {
  return new Promise((res) => {
    confirmAlert({
      text: "Are you sure you want to close the position?",
      onCancel: () => {
        res(false);
      },
      onConfirm: async () => {
        try {
          const response = await _requestProvider({
            method: "POST",
            path: "/close-position",
            token,
            host,
            params: {
              orderId,
              volume,
              symbol,
              token,
              closeDirection,
              options: {
                ...providerOptions,
              },
            },
          });

          if (response.data?.errorCode === 0) {
            NotificationManager.success("Position closed", providerName);
            dispatch(accountRequestThunk());
            res(true);
            return;
          }

          NotificationManager.error("Close position request error", providerName);
          res(false);
        } catch (e) {
          res(false);
        }
      },
    });
  });
};

export const requestProviderCancelOrder = async (
  dispatch,
  token,
  symbol,
  orderId,
  type,
  slPrice,
  tpPrice,
  closeDirection,
  volume,
  providerOptions,
  providerName,
  host
) => {
  return new Promise((res) => {
    confirmAlert({
      text: "Are you sure you want to cancel the stop order?",
      onCancel: () => {
        res(false);
      },
      onConfirm: async () => {
        try {
          const response = await _requestProvider({
            method: "POST",
            path: "/cancel-order",
            token,
            host,
            params: {
              token,
              orderId,
              symbol,
              type,
              slPrice,
              tpPrice,
              closeDirection,
              volume,
              options: {
                ...providerOptions,
              },
            },
          });

          if (response.data?.errorCode === 0) {
            NotificationManager.success("Order canceled", providerName);
            dispatch(accountRequestThunk());
            res(true);
            return;
          }

          NotificationManager.error("Cancel order request error", providerName);
          res(false);
        } catch (e) {
          NotificationManager.error("Cancel order request error", providerName);
          res(false);
        }
      },
    });
  });
};

export const requestProviderModifyOrder = async (
  dispatch,
  token,
  orderId,
  symbol,
  type,
  volume,
  closeDirection,
  slPrice,
  tpPrice,
  provideOptions,
  providerName,
  host
) => {
  return new Promise((res) => {
    confirmAlert({
      text: "Are you sure you want to change the stop order?",
      onCancel: () => {
        res(false);
      },
      onConfirm: async () => {
        try {
          const response = await _requestProvider({
            method: "POST",
            path: "/modify-order",
            token,
            host,
            params: {
              token,
              orderId,
              symbol,
              volume,
              type,
              closeDirection,
              slPrice,
              tpPrice,
              options: {
                ...provideOptions,
              },
            },
          });

          if (response.data?.errorCode === 0) {
            NotificationManager.success("Order modified", providerName);
            dispatch(accountRequestThunk());
            res(true);
            return;
          }

          NotificationManager.error("Modify order request error", providerName);
          res(false);
        } catch (e) {
          NotificationManager.error("Modify order request error", providerName);
          res(false);
        }
      },
    });
  });
};

export const _requestProvider = ({ method, path, token, params, host }) => {
  if (isDev) {
    host = devProviderServiceHost;
  }

  let queryString = "";
  let data = {
    version: packageJson.version,
    build: packageJson.hash,
    platform: isElectron() ? "desktop" : "web",
    env_type: appEnvType,
  };
  let headers = {};

  if (token) {
    headers = {
      "X-EXCHANGE-PROVIDER-TOKEN": `Bearer ${token}`,
    };
  }

  if (method === "GET" && params) {
    const queryParams = [];

    Object.entries(params).forEach(([key, value]) => {
      queryParams.push(`${key}=${value}`);
    });

    if (queryParams.length) {
      queryString = "?" + queryParams.join("&");
    }
  }

  if (method === "POST" && params) {
    data = { ...params };
  }

  return axios({
    method,
    url: `${host}/api/provider${path}${queryString}`,
    headers,
    data,
  });
};
