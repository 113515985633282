import React from "react";
import params from "../../config";

const View = ({
  brokerSettings,
  typeSettings,
  layoutSettings,
  versionInfo,
  appSettings,
  openLink,
  links,
}) => {
  return (
    <div className="menu">
      <div className="menu_box">
        {/* <!-- Верхние иконки в меню --> */}
        {/* <!-- В блоке с классом menu_item изначально выводится иконка в пассивном состоянии. Если к картинке добавляется класс active, она выглядит активной. --> */}
        <div className="menu_item" onClick={typeSettings.handleClick}>
          <div className={`menu_item_icon bar_chart ${typeSettings.isActive ? "active" : ""}`}></div>
        </div>
        <div className="menu_item" onClick={brokerSettings.handleClick}>
          <div className={`menu_item_icon person_outline ${brokerSettings.isActive ? "active" : ""}`}></div>
        </div>
        {/* <!-- Верхние иконки в меню конец --> */}
      </div>
      <div className="menu_box">
        {/* <!-- Нижние иконки в меню --> */}
        {/* <!-- Тот же принцип, что и в верхних --> */}
        {params.appEnvType !== "classic" && (
          <>
            <div
              className="menu_item"
              onClick={() => {
                openLink(links.charts);
              }}
            >
              <div className="menu_item_icon publicon"></div>
            </div>
            <div
              className="menu_item"
              onClick={() => {
                openLink(links.trends);
              }}
            >
              <div className="menu_item_icon trending"></div>
            </div>
            <div
              className="menu_item"
              onClick={() => {
                openLink(links.e_calendar);
              }}
            >
              <div className="menu_item_icon calendar"></div>
            </div>
          </>
        )}
        <div
          className="menu_item"
          onClick={() => {
            openLink(links.docs);
          }}
        >
          <div className="menu_item_icon info_outline"></div>
        </div>
        {/* <div className="menu_item" onClick={versionInfo.handleClick}>
          <div className={`menu_item_icon info_outline ${versionInfo.isActive ? 'active' : ''}`}></div>
        </div> */}
        <div className="menu_item" onClick={appSettings.handleClick}>
          <div className={`menu_item_icon settings ${appSettings.isActive ? "active" : ""}`}></div>
        </div>
        {/* <div
          className="menu_item"
          onClick={() => {
            openLink(links.docs);
          }}
        >
          <div className="menu_item_icon communication"></div>
        </div> */}
        <div
          className="menu_item"
          onClick={() => {
            openLink(links.gopro);
          }}
        >
          <img src="img/icons/pro.svg" alt="" />
        </div>
        {/* <!-- Нижние иконки в меню конец --> */}
      </div>
    </div>
  );
};

export default View;
