import React from "react";
import styled, { css } from "styled-components";
import radio from './assets/radio.svg';
import radioActive from './assets/radio-active.svg';

const Item = ({ active, onClick, label, labelStyle, style, disabled }) => {
  return (
    <Container onClick={onClick} style={{ ...style }} disabled={disabled}>
      <Radio active={active} disabled={disabled} />
      <Label active={active} disabled={disabled} style={{ ...labelStyle }}>
        {label}
      </Label>
    </Container>
  );
};
export default Item;

const Container = styled.div`
  ${(props) => {
    if (props.disabled === false)
      return css`
        cursor: pointer;
      `;
  }};
`;
const Radio = styled.div`
  width: 15px;
  height: 15px;
  position: absolute;
  margin-top: 3px;

  ${(props) => {
    if (props.active && !props.disabled)
      return css`
        background-image: url(${radioActive});
      `;
    else
      return css`
        background-image: url(${radio});
      `;
  }};
`;
const Label = styled.div`
  color: #fff;
  font-weight: bold;
  font-size: 17px;
  margin-left: 17px;

  ${(props) => {
    if (props.active && !props.disabled)
      return css`
        opacity: 1;
      `;
    else
      return css`
        opacity: 0.3;
      `;
  }};
`;
