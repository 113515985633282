import { combineReducers } from "redux";
// import handleActions from "redux-actions/lib/handleActions";
import { handleActions } from "redux-actions";
import {
  createSelectOption,
  setActiveProvider,
  setApplySettingsError,
  setConnectionError,
  setConnectionStatus,
  setOrdersPositionSize,
  setProviders,
  setSelectData,
  setSelectOption,
  toggleQuickBuy,
  setLoadingOrderPlacement,
  setOpenOrders,
  setAccountData,
  setPositionPnl,
  setProviderIntegrations,
  setProviderToken,
  setOrdersHistory,
  setIsOrdersHistoryLoading,
} from "./actions";

const list = handleActions(
  {
    [setProviders]: (state, action) => {
      if (!state.length) {
        return action.payload.map((provider, i) => {
          const { select, ordersPositionSize } = provider.fields;

          provider.isActive = i === 0;
          provider.ordersPositionSize = ordersPositionSize.defaults;
          provider.quickBuyStatus = false;
          provider.selectData = select.defaults;
          return provider;
        });
      }

      const newState = action.payload.reduce(
        (newState, provider) => {
          const { select, ordersPositionSize } = provider.fields;

          const found = newState.find((prov) => provider.name === prov.name);
          if (!found) {
            newState.push({
              ...provider,
              connectionStatus: provider.connectionStatus,
              ordersPositionSize: ordersPositionSize.defaults,
              quickBuyStatus: false,
              selectData: select.defaults,
            });

            return newState;
          }
          found.type = provider.type;
          found.fields = provider.fields;
          found.connectionStatus = provider.connectionStatus;
          found.selectData = found.selectData.length ? found.selectData : provider.fields.select.defaults;
          found.how_to_url = provider.how_to_url;

          return newState;
        },
        [...state]
      );

      const hasActive = newState.find((provider) => provider.isActive);

      if (!hasActive) {
        newState[0].isActive = true;
      }

      return newState.filter((provider) => {
        const isExist = action.payload.find((pr) => pr.name === provider.name);

        return !!isExist;
      });
    },
    [setActiveProvider]: (state, action) => {
      return state.map((provider) => {
        provider.isActive = provider.name === action.payload;
        return provider;
      });
    },
    [setSelectData]: (state, action) => {
      const { providerName, select } = action.payload;

      if (!select) return state;

      return state.map((provider) => {
        if (provider.name.toLowerCase() === providerName.toLowerCase()) {
          provider.selectData = [...select];
        }
        return provider;
      });
    },
    [setConnectionStatus]: (state, action) => {
      const { providerName, status } = action.payload;

      return state.map((provider) => {
        provider.connectionStatus =
          provider.name.toLowerCase() === providerName ? status : provider.connectionStatus;
        return provider;
      });
    },
    [setOrdersPositionSize]: (state, action) => {
      const { index, value } = action.payload;

      const active = state.find((provider) => provider.isActive);
      active.ordersPositionSize.splice(index, 1, value);
      active.ordersPositionSize = [...active.ordersPositionSize];

      return [...state.filter((provider) => provider.name !== active.name), active];
    },
    [toggleQuickBuy]: (state) => {
      const active = state.find((provider) => provider.isActive);
      active.quickBuyStatus = !active.quickBuyStatus;

      return [...state];
    },
    [createSelectOption]: (state, action) => {
      const active = state.find((provider) => provider.isActive);
      if (!active.fields.select.creatable) return state;

      active.selectData.push({
        value: {
          leverage: action.payload,
        },
        label: action.payload,
      });

      active.selectData = active.selectData.sort((a, b) => {
        if (a.label === "maximum") return 1;
        if (b.label === "maximum") return -1;
        return parseInt(a.label) - parseInt(b.label);
      });

      return [...state];
    },
    [setSelectOption]: (state, action) => {
      const active = state.find((provider) => provider.isActive);

      active.selectData.forEach((option) => {
        option.selected = action.payload === option.label;
      });

      return [...state];
    },
    [setOpenOrders]: (state, action) => {
      const active = state.find((provider) => provider.isActive);

      active.openOrders = action.payload;

      return [...state];
    },
    [setPositionPnl]: (state, action) => {
      const active = state.find((provider) => provider.isActive);
      const { orderId, pnl } = action.payload;
      const position = active.openOrders.positions.find((position) => position.orderId === orderId);
      position.pnl = pnl;

      return [...state];
    },
    [setAccountData]: (state, action) => {
      const active = state.find((provider) => provider.isActive);

      if (!active) return state;

      const { currency, balance, contracts } = action.payload;

      active.account = {
        currency,
        balance,
        contracts,
      };

      return [...state];
    },
    [setOrdersHistory]: (state, action) => {
      const active = state.find(({ isActive }) => isActive);

      if (!active) return state;

      active.ordersHistory = action.payload;

      return [...state];
    },
  },
  []
);

const integrations = handleActions(
  {
    [setProviderIntegrations]: (state, action) => action.payload,
    [setProviderToken]: (state, action) => {
      const { provider, token } = action.payload;
      return {
        ...state,
        [provider]: token,
      };
    },
  },
  {}
);

const isOrderHistoryLoading = handleActions(
  {
    [setIsOrdersHistoryLoading]: (state, action) => action.payload,
  },
  false
);

const isLoadingOrderPlacement = handleActions(
  {
    [setLoadingOrderPlacement]: (state, action) => action.payload,
  },
  false
);

const connectionError = handleActions(
  {
    [setConnectionError]: (_, action) => action.payload,
  },
  null
);

const applySettingsError = handleActions(
  {
    [setApplySettingsError]: (_, action) => action.payload,
  },
  null
);

export default combineReducers({
  integrations,
  list,
  isOrderHistoryLoading,
  isLoadingOrderPlacement,
  connectionError,
  applySettingsError,
});
