import React from "react";
import styled from "styled-components";
import LoadingIndicator from "./LoadingIndicator";

const OrderButton = ({ disabled, loading, colors, onPlaceOrder, children }) => {
  return (
    <Button disabled={disabled} colors={colors} onClick={onPlaceOrder}>
      {loading ? <LoadingIndicator /> : children}
    </Button>
  );
};

const Button = styled.button`
  border-radius: 5px;
  padding: 5px 10px;
  background-color: ${(props) => props.colors.main};
  color: #3a372f;
  border: none;
  height: 28px;
  overflow: hidden;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  font-family: "Montserrat";
  transition: all 0.3s;
  &:hover {
    background-color: ${(props) => props.colors.hover};
  }
`;

export default OrderButton;
