import React, { Component } from "react";
import isElectron from "is-electron";
import packageJson from "../../../package.json";

class TabContent extends Component {
  state = { isInitialized: false, addedListeners: false };

  iframe = React.createRef();

  componentDidMount() {
    this.check();
  }

  componentDidUpdate() {
    this.check();
  }

  check = () => {
    const { tabId, setTabTitle } = this.props;
    const { isInitialized } = this.state;

    if (typeof setTabTitle === "function" && !isInitialized) {
      this.setState({ isInitialized: true }, () => {
        if (isElectron()) {
          this.webview.addEventListener("page-title-updated", () => {
            setTabTitle({ id: tabId, title: this.webview.getTitle() });
          });
        } else {
          // const { current: iframe } = this.iframe;
          // iframe.addEventListener("load", (e) => {
          setTabTitle({ id: tabId, title: `Tab ${tabId}` });
          // });
        }
      });
    }
  };

  renderWeb() {
    const { url, isResizing, isActive } = this.props;
    let style = { height: "100%", width: "100%" };
    if (!isActive || isResizing) style = { ...style, display: "none" };

    return <iframe ref={this.iframe} src={url} title={url} style={style} />;
  }

  renderElectron() {
    const { url, isResizing, isActive, tabId, setTabTitle, userAgent } = this.props;
    const { addedListeners } = this.state;

    let style = { height: "100%" };
    if (!isActive || isResizing) style = { ...style, display: "none" };
    return (
      <webview
        ref={(webview) => {
          this.webview = webview;
          if (!!webview && !addedListeners) {
            webview.addEventListener("new-window", (e) => {
              if (e.url.indexOf("vfxaction=newwnd") > -1)
                window.shell.openExternal(e.url);
              else webview.src = e.url;
            });
            webview.addEventListener('console-message', (e) => {
              console.log('Guest page console message:', { url, e })
            })

            this.setState({ addedListeners: true });
          }
        }}
        src={url}
        autosize="on"
        style={style}
        useragent={userAgent}
      />
    );
  }

  render() {
    return isElectron() ? this.renderElectron() : this.renderWeb();
  }
}

export default TabContent;
