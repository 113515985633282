import { combineReducers } from "redux";
import signals from "./signals";
import indicators from "./indicators";
import client from "./client";
import dashboard from "./dashboard";
import mainSettings from "./mainSettings";
import initial from "./initial";
import filtersSettings from "./filtersSettings";
import storageVersion from "./storageVersion";
import providers from "./providers";
import quotes from "./quotes";
import chart from "./charts";

export default combineReducers({
  signals,
  indicators,
  client,
  dashboard,
  mainSettings,
  filtersSettings,
  initial,
  providers,
  quotes,
  storageVersion,
  chart,
});
