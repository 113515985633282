import React from "react";
import styled from "styled-components";

import Signal from "../Signal";

const Front = (props) => {
  if (!props.data) return null;
  return (
    <Wrapper>
      <Signal {...props} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //min-height: 220px;
  backface-visibility: hidden;
  z-index: 2;
  transform: rotateY(0deg);
`;

export default Front;
