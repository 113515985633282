import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { setPresetError, setFiltersAsset, deletePreset, changePreset, toggleSignalFavorite } from "./actions";

export default combineReducers({
  assets: handleActions(
    {
      [setFiltersAsset]: (state, action) => {
        return [...state.filter(({ id }) => id !== action.payload.id), action.payload];
      },
      [deletePreset]: (state, action) => {
        const presetId = action.payload;

        const currentAsset = state.find((preset) => preset.presetId === presetId);

        if (currentAsset) {
          return [
            ...state.filter(({ presetId }) => presetId !== currentAsset.presetId),
            {
              selected: currentAsset.selected,
              power: currentAsset.power,
              algorithm: currentAsset.algorithm,
              expiration: currentAsset.expiration,
              id: currentAsset.id,
            },
          ];
        }

        return state;
      },
    },
    []
  ),
  presets: handleActions(
    {
      [setFiltersAsset]: (state, action) => {
        const { presetName } = action.payload;

        if (!presetName) return state;

        const isset = state.find((preset) => preset.presetName === presetName);

        return [
          ...state.filter((preset) => preset.presetName !== presetName),
          {
            ...action.payload,
            createdAt: !!isset ? action.payload.createdAt : Date.now(),
          },
        ].sort((a, b) => b.createdAt - a.createdAt);
      },
      [deletePreset]: (state, action) => {
        return state.filter((preset) => preset.presetId !== action.payload);
      },
      [changePreset]: (state, action) => {
        return [...state.filter((preset) => preset.presetName !== action.payload.presetName), action.payload];
      },
    },
    []
  ),
  presetError: handleActions(
    {
      [setPresetError]: (_, action) => action.payload,
    },
    false
  ),
  favorites: handleActions(
    {
      [toggleSignalFavorite]: (state, action) => {
        const symbol = action.payload;

        if (state.includes(symbol)) {
          state = state.filter((symb) => symb !== symbol);
        } else {
          state.push(symbol);
        }

        return [...state];
      },
    },
    []
  ),
});
