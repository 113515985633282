import { receiveSignals } from "../signals";
import { receiveIndicators } from "../indicators";
import { updateTms } from "../client";
import { getAccountInfoEmail, isDebug, getWShost, getWSchannel } from "../initial";
import { WSConnection } from "./wsConnection";
import packageJson from "../../../package.json";
import { NotificationManager } from "react-notifications";
import pako from "pako";

export const connectSocket = () => (dispatch, getState) => {
  const wsConnection = new WSConnection({
    email: getAccountInfoEmail(getState()),
    // lkey: getAccountInfoLkey(getState()),
    // isDebug: isDebug(getState()),
    version: packageJson.version,
    build: packageJson.hash,
    platform: window.platform ?? "browser",
    host: getWShost(getState()),
    channel: getWSchannel(getState()),
  });

  wsConnection
    .subscribe("SIGNALS_INDICATORS_ROOM_GZIP", (message) => {
      let json = {};
      try {
        json = JSON.parse(message);
      } catch (e) {
        console.error("Invalid json SIGNALS_INDICATORS_ROOM_GZIP data", message);
        return;
      }

      const { tms, data } = json;
      if (!tms || !data) {
        console.error("Invalid format SIGNALS_INDICATORS_ROOM_GZIP data", message);
        return;
      }

      const dataParsed = unpackGzip(data);

      dispatch(receiveSignals(dataParsed.signals));
      dispatch(receiveIndicators(dataParsed.indicators));
      dispatch(updateTms(tms));
    })

    .subscribe("SYSTEM_MESSAGE", (message) => {
      let json = {};
      try {
        json = JSON.parse(message);
      } catch (e) {
        console.error("Invalid json SYSTEM_MESSAGE data", message);
        return;
      }

      const { type, text, title } = json;
      if (!type || !text) {
        console.error("Invalid format SYSTEM_MESSAGE data", message);
        return;
      }
      if (isDebug(getState())) {
        sendNotification(type, text, title);
      }
    });
};

const unpackGzip = (data) => {
  // Decode base64 (convert ascii to binary)
  const strData = atob(data);
  // Convert binary string to character-number array
  const charData = strData.split("").map(function (x) {
    return x.charCodeAt(0);
  });

  // Turn number array into byte-array
  const binData = new Uint8Array(charData);
  // Pako magic
  const packageData = new Uint16Array(pako.inflate(binData));

  let json = "";

  for (let i = 0; i < Math.ceil(packageData.length / 1000); i++) {
    const chunk = packageData.slice(i * 1000, (i + 1) * 1000);
    json += String.fromCharCode(...new Uint16Array(chunk));
  }

  return JSON.parse(json);
};

// const unpackGzip = (data) => {
//   // Decode base64 (convert ascii to binary)
//   const strData = atob(data);
//   // Convert binary string to character-number array
//   const charData = strData.split("").map(function (x) {
//     return x.charCodeAt(0);
//   });

//   // let json = "";
//   // while (charData.length) {
//   //   let chunk = 8 * 1024;
//   //   const chunk = charData.splice(0, 1000);
//   //   // Turn number array into byte-array
//   //   const binData = new Uint8Array(chunk);
//   //   // Pako magic
//   //   const packageData = pako.inflate(binData);
//   //   json += String.fromCharCode.apply(null, new Uint16Array(packageData));
//   // }

//   // Turn number array into byte-array
//   const binData = new Uint8Array(charData);
//   // Pako magic
//   const packageData = pako.inflate(binData);
//   // Convert gunzipped byteArray back to ascii string:
//   const json = String.fromCharCode(...new Uint16Array(packageData));

//   return JSON.parse(json);
// };

const sendNotification = (type, text, title = null) => {
  switch (type) {
    case "info":
      NotificationManager.info(text, title);
      break;
    case "success":
      NotificationManager.success(text, title);
      break;
    case "warning":
      NotificationManager.warning(text, title);
      break;
    case "error":
      NotificationManager.error(text, title);
      break;
    default:
      break;
  }
};
