import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getDashboardItems,
  setTerm,
  setSymbol,
  initDashboard,
} from "../../modules/dashboard";
import { isClientPRO } from "../../modules/mainSettings";
import { getIndicators } from "../../modules/indicators";
import { getFreeAssets } from "../../modules/initial";
import Dashboard from "../Dashboard";
import styled from "styled-components";

class DashboardPanel extends Component {
  componentDidMount() {
    const { isPro, setSymbol, freeAssets } = this.props;
    if (!isPro) initDashboard([...freeAssets]);
  }

  findDashboardData = ({ symbol: dashboardSymbol, term }) => {
    if (!this.props.indicators) return null;

    return this.props.indicators.find(({ symbol, period }) => {
      if (!symbol || !period) return false;
      return (
        dashboardSymbol.toLowerCase() === symbol.toLowerCase() &&
        term.toString() === period.toString()
      );
    });
  };

  render() {
    const { setTerm, dashboardItems, scale, handleResize } = this.props;

    const showFirstLine = dashboardItems.slice(0, 4).reduce((result, { enabled }) => (result || enabled), false);
    const showSecondLine = dashboardItems.slice(4).reduce((result, { enabled }) => (result || enabled), false);

    const shiftedScale = showFirstLine && showSecondLine ? scale / 2 : scale;
    handleResize();

    return (
      <Wrapper ref={this.props.transformWrapper}>
        {showFirstLine && (<Item style={{ transform: `scale(${shiftedScale})` }}>
          {dashboardItems.map((item, index) => {
              if (index > 3) return null;
              return (
                <Dashboard
                  key={index}
                  onTermChange={(value) => {
                    setTerm({ dashboardId: index, value });
                  }}
                  {...item}
                  values={this.findDashboardData(item)}
                />
              );
            },
          )}
        </Item>)}
        {showSecondLine && (<Item style={{ transform: `scale(${shiftedScale})` }}>
          {dashboardItems.map((item, index) => {
            if (index < 4) return null;
            return (
                <Dashboard
                  key={index}
                  onTermChange={(value) => {
                    setTerm({ dashboardId: index, value });
                  }}
                  {...item}
                  values={this.findDashboardData(item)}
                />
              );
            },
          )}
        </Item>)}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background: #232938;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px;
  overflow: hidden;
  height: 100%;
  min-height: 150px;
`;
const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  position: relative;
  transform-origin: top left;
  height: 50%;
`;

export default connect(
  (state) => ({
    dashboardItems: getDashboardItems(state),
    indicators: getIndicators(state),
    freeAssets: getFreeAssets(state),
    isPro: isClientPRO(state),
  }),
  { setTerm, setSymbol }
)(DashboardPanel);
