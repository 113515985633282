import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import View from "./View";
import Signal from "../Signal";
import {
  getAlgorithms,
  getFreeAssets,
  getLinks,
  getOrdersStrategy,
  getSignalPeriods,
  getSignalTypesList,
} from "../../../../modules/initial";
import { getActiveSignalsType, getSignalView, newTab } from "../../../../modules/client";
import { isClientPRO } from "../../../../modules/mainSettings";
import { setEnabled, setSymbol, setTerm } from "../../../../modules/dashboard/actions";
import { getFavoriteSymbols, getFiltersPresets } from "../../../../modules/filtersSettings";
import { getIsLoadingOrderPlacement } from "../../../../modules/providers";
import AdaptiveSignal from "../AdaptiveSignal";
import { isEqualObjects } from "../../../../utils";

class SignalsList extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    signals: PropTypes.array.isRequired,
    freeAssets: PropTypes.array.isRequired,
  };

  state = {
    increment: 0,
  };

  interval;

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ increment: this.state.increment + 1 });
    }, 500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.isPro !== this.props.isPro) return true;
    if (nextProps.variant !== this.props.variant) return true;

    if (nextProps.signals.length !== this.props.signals.length) return true;

    for (let i = 0; i < nextProps.signals.length; i++) {
      if (!nextProps.signals[i]?.trending || !nextProps.signals[i]?.reversal) continue;
      if (!isEqualObjects(nextProps.signals[i], this.props.signals[i])) {
        return true;
      }
    }

    return false;
  }

  handleSetSymbolClicked = (term, symbol) => {
    const { setEnabled, setSymbol, setTerm } = this.props;
    setEnabled({ dashboardId: 0, value: true });
    setSymbol({ dashboardId: 0, value: symbol });
    setTerm({ dashboardId: 0, value: term });
  };

  render() {
    const {
      signals,
      algorithms,
      isPro,
      freeAssets,
      variant,
      ordersStrategy,
      activeSignalsType,
      presets,
      availableTypesList,
      favoriteSymbols,
      isLoadingOrderPlacement,
      periods,
    } = this.props;

    let activePresetName = availableTypesList[activeSignalsType.id];
    const activePeriod = periods?.find((period) => period.value === activeSignalsType.term)?.label || "";

    const isAdaptiveSignalsType =
      activeSignalsType.id === "forex_adaptive" || activeSignalsType.id === "crypto_adaptive";

    if (!activePresetName) {
      const preset = presets.find((preset) => preset.presetId === activeSignalsType.id);
      if (preset) {
        activePresetName = preset.presetName;
      } else {
        activePresetName = "Favorites";
        if (activeSignalsType.id === "forex_adaptive") {
          activePresetName = "Forex Adaptive";
        }
        if (activeSignalsType.id === "crypto_adaptive") {
          activePresetName = "Crypto Adaptive";
        }
      }
    }

    return (
      <View
        {...this.props}
        increment={this.state.increment}
        activePresetName={activePresetName}
        activePeriod={activePeriod}
      >
        {isAdaptiveSignalsType ? (
          <>
            {signals &&
              signals.length > 0 &&
              signals.map(({ trending, reversal }) => {
                if (!trending && !reversal) return null;

                let showFull = isPro;

                if (freeAssets.indexOf(trending?.symbol) !== -1) showFull = true;
                if (freeAssets.indexOf(reversal?.symbol) !== -1) showFull = true;

                const id =
                  trending?.symbol +
                  reversal?.symbol +
                  trending?.period +
                  reversal?.period +
                  trending?.timestamp +
                  reversal?.timestamp +
                  trending?.alg +
                  reversal?.alg +
                  trending?.signal +
                  reversal?.signal;

                let trendingHmArray = [];
                let reversalHmArray = [];

                if (trending) {
                  trendingHmArray = Object.values(trending.hm);
                }

                if (reversal) {
                  reversalHmArray = Object.values(reversal.hm);
                }

                const trendingAvgHm =
                  trendingHmArray.reduce((avg, cur) => avg + cur, 0) / trendingHmArray.length;
                const reversalAvgHm =
                  reversalHmArray.reduce((avg, cur) => avg + cur, 0) / reversalHmArray.length;

                let frontSignal = trendingAvgHm > reversalAvgHm ? trending : reversal;
                let backSignal = trendingAvgHm > reversalAvgHm ? reversal : trending;

                if (!frontSignal) {
                  frontSignal = backSignal;
                }

                if (!backSignal) {
                  backSignal = frontSignal;
                }

                return (
                  <AdaptiveSignal
                    key={id}
                    isAdaptive
                    frontSignal={frontSignal}
                    backSignal={backSignal}
                    variant={variant}
                    algorithms={algorithms}
                    showFull={showFull}
                    // currentTms={currentTms}
                    ordersStrategy={ordersStrategy}
                    isLoadingOrderPlacement={isLoadingOrderPlacement}
                    onSetSymbolClicked={this.handleSetSymbolClicked}
                    onOpenChartsClicked={this.handleOpenChartsClicked}
                  />
                );
              })}
          </>
        ) : (
          <>
            {signals &&
              signals.length > 0 &&
              signals.map((signal, index) => {
                const { timestamp, symbol, period, alg } = signal;

                const id = symbol + period + timestamp + alg + signal.signal;
                const isFavorite = favoriteSymbols.includes(symbol);

                let showFull = isPro;
                if (freeAssets.indexOf(symbol.toLowerCase()) !== -1) showFull = true;

                return (
                  <Signal
                    key={id}
                    variant={variant}
                    data={signal}
                    algorithms={algorithms}
                    showFull={showFull}
                    // currentTms={currentTms}
                    isFavorite={isFavorite}
                    ordersStrategy={ordersStrategy}
                    isLoadingOrderPlacement={isLoadingOrderPlacement}
                    onSetSymbolClicked={this.handleSetSymbolClicked}
                    onOpenChartsClicked={this.handleOpenChartsClicked}
                  />
                );
              })}
          </>
        )}
      </View>
    );
  }
}

export default connect(
  (state) => ({
    // signals: getSignals(state),
    algorithms: getAlgorithms(state),
    freeAssets: getFreeAssets(state),
    isPro: isClientPRO(state),
    // currentTms: getCurrentTms(state),
    links: getLinks(state),
    variant: getSignalView(state),
    isLoadingOrderPlacement: getIsLoadingOrderPlacement(state),
    ordersStrategy: getOrdersStrategy(state),
    activeSignalsType: getActiveSignalsType(state),
    presets: getFiltersPresets(state),
    availableTypesList: getSignalTypesList(state),
    favoriteSymbols: getFavoriteSymbols(state),
    periods: getSignalPeriods(state),
  }),
  { setSymbol, setTerm, setEnabled, newTab }
)(SignalsList);
