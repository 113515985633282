import React, { Component } from "react";
import Panel from "./Panel";
import Tab from "./Tab";
import TabContent from "./TabContent";
import TabContentWrapper from "./TabContentWrapper";
import { connect } from "react-redux";
import {
  getTabs,
  getChartTabs,
  closeTab,
  getActiveTabId,
  setActiveTab,
  getActiveBroker,
  setTabTitle,
  getTerminalTabData,
} from "../../modules/client";
import { getCustomBrokersList } from "../../modules/mainSettings";
import { getBrokersList, getLinks, getUserAgent } from "../../modules/initial";
import AppTerminal from "./AppTerminal";

const BROKER_TAB_ID = "brokerTab";

class WebTabsPanel extends Component {
  componentDidMount() {
    this.handleTabSelect(BROKER_TAB_ID);
  }

  handleTabSelect = (id) => {
    if (this.props.activeTabId !== id) this.props.setActiveTab(id);
  };

  handleTabClose = (id) => {
    // if (id === TERMINAL_TAB_ID) {
    //   const { setTerminalDataThunk } = this.props;
    //   setTerminalDataThunk(null);
    //   return;
    // }
    this.props.closeTab(id);
  };

  render() {
    const {
      tabs,
      chartTabs,
      isResizing,
      activeBroker,
      availableBrokersList,
      customBrokersList,
      setTabTitle,
      links,
      userAgentPreset,
    } = this.props;

    const activeBrokerObj = availableBrokersList?.find(({ code }) => code === activeBroker);
    let brokerTab,
      url = activeBrokerObj?.url,
      title = activeBrokerObj?.title || activeBroker || "";

    if (!url) {
      const customBroker = customBrokersList.find(({ title }) => title === activeBroker);
      url = customBroker ? `https://vfxalert.com/app/r/?url=${customBroker.url}` : null;
    }

    if (!url) {
      if (!links["hellopage"]) return null;
      brokerTab = {
        title: "Hello!",
        url: links["hellopage"],
      };
    } else {
      brokerTab = { title, url };
    }

    const { activeTabId } = this.props;
    let activeTab = tabs.find(({ id }) => id === activeTabId);
    if (!activeTab) {
      activeTab = { ...brokerTab };
    }

    const activeChartTab = chartTabs.find((tab) => tab.id === activeTabId) || null;

    return (
      <>
        <Panel>
          <Tab
            id={BROKER_TAB_ID}
            isActive={BROKER_TAB_ID === activeTabId}
            onSelect={this.handleTabSelect}
            onClose={null}
            {...brokerTab}
          />
          {chartTabs &&
            chartTabs.map((chartTab) => (
              <Tab
                key={chartTab.id}
                {...chartTab}
                isActive={chartTab.id === activeTabId}
                onSelect={this.handleTabSelect}
                onClose={this.handleTabClose}
              />
            ))}
          {tabs.map((tabItem) => (
            <Tab
              key={tabItem.id}
              {...tabItem}
              isActive={tabItem.id === activeTabId}
              onSelect={this.handleTabSelect}
              onClose={this.handleTabClose}
            />
          ))}
        </Panel>
        {/* {activeTab && (
          <TabContent url={activeTab.url} isResizing={isResizing} />
        )} */}
        <TabContentWrapper>
          <TabContent
            url={brokerTab.url}
            isResizing={isResizing}
            isActive={BROKER_TAB_ID === activeTabId}
            userAgent={userAgentPreset}
          />
          {activeChartTab && <AppTerminal isActive {...activeChartTab} />}
          {tabs.map(({ url, id, data }) => {
            return (
              <TabContent
                key={id}
                tabId={id}
                url={url}
                isResizing={isResizing}
                isActive={id === activeTabId}
                setTabTitle={setTabTitle}
                userAgent={userAgentPreset}
              />
            );
          })}
        </TabContentWrapper>
      </>
    );
  }
}

export default connect(
  (state) => ({
    tabs: getTabs(state),
    chartTabs: getChartTabs(state),
    activeTabId: getActiveTabId(state),
    activeBroker: getActiveBroker(state),
    availableBrokersList: getBrokersList(state),
    customBrokersList: getCustomBrokersList(state),
    links: getLinks(state),
    userAgentPreset: getUserAgent(state),
    terminalTabData: getTerminalTabData(state),
  }),
  { closeTab, setActiveTab, setTabTitle }
)(WebTabsPanel);
