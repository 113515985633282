import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isElectron from "is-electron";
import View from "./View";
import { getLinks } from "../../modules/initial";
import { newTab } from "../../modules/client";
import { openLink } from "../../utils"

class Menu extends Component {
  static propTypes = {
    brokerSettings: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
    typeSettings: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
    appSettings: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
    versionInfo: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
    links: PropTypes.shape({
      home: PropTypes.string.isRequired,
      e_calendar: PropTypes.string.isRequired,
      charts: PropTypes.string.isRequired,
      trends: PropTypes.string.isRequired,
      docs: PropTypes.string.isRequired,
      gopro: PropTypes.string.isRequired,
    }).isRequired,
  };

  openLink = (url) => {
    openLink(url, (tabUrl) => { this.props.newTab(tabUrl) })
  };

  render() {
    return <View {...this.props} openLink={this.openLink} />;
  }
}

export default connect(
  (state) => ({
    links: getLinks(state),
  }),
  { newTab }
)(Menu);
