import { getFavoriteSymbols, getFiltersAssets, getFiltersPresets } from "../filtersSettings";
import { getActiveSignalsType } from "../client";
import { getAvailableSignalType } from "../initial";

const TEST_SIGNALS_MARKET = 9;

export const getSignalsMarketAlg = (state) => {
  const { id } = getActiveSignalsType(state);
  return getAvailableSignalType(state, parseInt(id));
};

export const getSignals = (state) => {
  let list = state.signals.list;
  const { id: typeId, term: signalsTerm } = getActiveSignalsType(state);
  const clientFilter = getAvailableSignalType(state, parseInt(typeId));

  // фильтруем по adaptive alg
  if (typeId === "forex_adaptive" || typeId === "crypto_adaptive") {
    const adaptiveMarketId = typeId === "forex_adaptive" ? 0 : 1;

    const trendingSignals = list.filter(({ alg, market_id, period }) => {
      if (market_id !== adaptiveMarketId) return false;
      if (alg !== 16) return false;
      if (!signalsTerm) return true;
      return alg === 16 && period === signalsTerm;
    });

    const reversalSignals = list.filter(({ alg, market_id, period }) => {
      if (market_id !== adaptiveMarketId) return false;
      if (alg !== 17) return false;
      if (!signalsTerm) return true;
      return period === signalsTerm;
    });

    const signalList = [];

    trendingSignals.forEach((signal) => {
      const reversalSignal = reversalSignals.find(
        (reversalSignal) =>
          signal.symbol === reversalSignal.symbol &&
          signal.period === reversalSignal.period &&
          signal.signal !== reversalSignal.signal
      );

      signalList.push({ trending: signal, reversal: reversalSignal || null });
    });

    reversalSignals.forEach((signal) => {
      const isExist = signalList.find(
        (pair) =>
          pair.reversal?.symbol === signal.symbol &&
          pair.reversal?.period === signal.period &&
          pair.reversal?.signal === signal.signal
      );
      if (isExist) return;

      signalList.push({ reversal: signal, trending: null });
    });

    return signalList;
  }

  // фильтруем по по избранным сигналам
  if (typeId === "favorites") {
    const favoriteSymbolList = getFavoriteSymbols(state);
    return list.filter(({ symbol, period }) => {
      if (!favoriteSymbolList.includes(symbol.toUpperCase())) return false;
      const termValue = parseInt(period);
      if (!signalsTerm) return true;
      return termValue === signalsTerm;
    });
  }

  // фильтруем по custom preset
  if (!clientFilter) {
    const preset = getFiltersPresets(state).find(({ presetId }) => presetId === typeId);

    return list.filter(({ symbol, power, alg, period }) => {
      if (preset.selected.indexOf(symbol.toUpperCase()) < 0) return false;
      if (preset.power.indexOf(parseInt(power)) < 0) return false;
      if (preset.algorithm.indexOf(alg.toString()) < 0) return false;

      const termValue = parseInt(period);
      if (!preset.expiration.find((item) => item.value === termValue)) return false;
      if (!signalsTerm) return true;

      return parseInt(signalsTerm) === termValue;
    });
  }

  // фильтруем по предустановленным presets из initial->available-signals-category
  return list.filter(({ market_id, alg_type, alg, period }) => {
    let result;
    if (alg === 16 || alg === 17) return false;
    if (clientFilter?.market_id === TEST_SIGNALS_MARKET) {
      result = TEST_SIGNALS_MARKET === market_id;
    } else {
      result = clientFilter?.alg_type === alg_type && clientFilter?.market_id === market_id;
    }
    return signalsTerm ? result && signalsTerm === period : result;
  });
};
