import React from "react";
import styled from "styled-components";

import Variant1 from "./Variant1";
import Variant2 from "./Variant2";
import Variant3 from "./Variant3";
import Variant4 from "./Variant4";

const VariantsContainer = (props) => {
  const { variant, alg, period, updatedServerSync } = props;

  return (
    <Container>
      <PriceContainer>
        {variant === 1 && <Variant1 {...props} />}
        {variant === 2 && <Variant2 {...props} />}
        {variant === 3 && <Variant3 {...props} />}
        {variant === 4 && <Variant4 {...props} />}
      </PriceContainer>
    </Container>
  );
};

export default VariantsContainer;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const PriceContainer = styled.div`
  margin: 3px 0;
`;
