import React, {Fragment} from "react";

const View = ({ value, handleClick }) => (
  <div className="graph_bottom_time">
    {/* <!-- Картинка времени --> */}
    {/* <!-- Изначально все картинки скрыты. К той картинке, к которой добавлен класс graph_bottom_time_img1-graph_bottom_time_img4 становится видна --> */}
    <div className="graph_bottom_time_img graph_bottom_time_img">
      {[{ label: '5m', value: 5 }, { label: '15m', value: 15 }, { label: '1h', value: 60 }, { label: '4h', value: 240 }].map((item, index) => (
        <Fragment key= {index}>
          <div
            className="graph_bottom_time_minute"
            onClick={() => {
              handleClick(item.value);
            }}
          >
            {item.label}
          </div>
          {item.value === value && (
            <div
              className={`graph_bottom_time_minute_active graph_bottom_time_minute_active${index +
                1}`}
            >
              {item.label}
            </div>
          )}
        </Fragment>
      ))}
    </div>
    {/* <!-- Картинка времени конец --> */}

    {/* <!-- Иконка часов --> */}
    {/* <!-- Изначально показывается серая иконка. Если добавить к классу graph_bottom_time_icon класс active становится видна белая иконка --> */}
    <div className="graph_bottom_time_icon"></div>
    {/* <!-- Иконка часов конец --> */}
  </div>
);

export default View;