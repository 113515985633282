/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import AdaptiveAlg from "./AdaptiveAlg";

const View = ({
  isActive,
  list,
  presets,
  periods,
  onClick,
  activeItem: { id: activeId, term: activeTerm },
}) => {
  return (
    <>
      {/* <!-- Список тип сигнала --> */}
      {/* <!-- Изначально скрыт, появляется при добавлении класса active --> */}
      <div className={`signal_type ${isActive ? "active" : ""}`}>
        <div className="signal_type_title">Signals type</div>
        <div className="signal_type_wrap">
          {/*<div className="signal_type_item">*/}
          {/*  /!* <!-- Элемент Signal Type с галочкой --> *!/*/}
          {/*  /!* <!-- Изначально выглядит пассивным. При добавлении класса active становится активным. Галочка становится  --> *!/*/}
          {/*  <div className={`signal_type_name_box ${"favorites" === activeId.toString() ? "active" : ""}`}>*/}
          {/*    <span></span>*/}
          {/*    <div className="signal_type_name">Favorites</div>*/}
          {/*  </div>*/}
          {/*  /!* <!-- Элемент Signal Type с галочкой конец --> *!/*/}
          {/*  <div className="signal_type_graph">*/}
          {/*    /!* <!-- Цифровые значения Signal Type --> *!/*/}
          {/*    /!* <!-- Изначально все элементы пассивные. При добавлении класса active становится активным. Цифра обводится в круглешок и становится белой  --> *!/*/}
          {/*    {periods?.map(({ label, value }) => (*/}
          {/*      <div*/}
          {/*        className={"favorites" === activeId.toString() && activeTerm === value ? "active" : ""}*/}
          {/*        key={value}*/}
          {/*        onClick={() => {*/}
          {/*          onClick({ id: "favorites", term: value });*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {label}*/}
          {/*      </div>*/}
          {/*    ))}*/}
          {/*    <div*/}
          {/*      className={"favorites" === activeId.toString() && !activeTerm ? "active" : ""}*/}
          {/*      onClick={() => {*/}
          {/*        onClick({ id: "favorites", term: null });*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      All*/}
          {/*    </div>*/}
          {/*    /!* <!-- Цифровые значения Signal Type конец --> *!/*/}
          {/*  </div>*/}
          {/*</div>*/}
          {[
            { algId: "forex_adaptive", algName: "Forex Adaptive" },
            { algId: "crypto_adaptive", algName: "Crypto Adaptive" },
          ].map((alg) => (
            <AdaptiveAlg
              algId={alg.algId}
              algName={alg.algName}
              activeId={activeId}
              periods={periods}
              activeTerm={activeTerm}
              onPeriodClicked={onClick}
            />
          ))}
          {list &&
            list.length &&
            list.map(([id, name]) => (
              <div className="signal_type_item" key={id}>
                {/* <!-- Элемент Signal Type с галочкой --> */}
                {/* <!-- Изначально выглядит пассивным. При добавлении класса active становится активным. Галочка становится  --> */}
                <div
                  className={`signal_type_name_box ${id.toString() === activeId.toString() ? "active" : ""}`}
                >
                  <span></span>
                  <div className="signal_type_name">{name}</div>
                </div>
                {/* <!-- Элемент Signal Type с галочкой конец --> */}
                <div className="signal_type_graph">
                  {/* <!-- Цифровые значения Signal Type --> */}
                  {/* <!-- Изначально все элементы пассивные. При добавлении класса active становится активным. Цифра обводится в круглешок и становится белой  --> */}
                  {periods?.map(({ label, value }) => (
                    <div
                      className={
                        id.toString() === activeId.toString() && activeTerm === value ? "active" : ""
                      }
                      key={value}
                      onClick={() => {
                        onClick({ id, term: value });
                      }}
                    >
                      {label}
                    </div>
                  ))}
                  <div
                    className={id.toString() === activeId.toString() && !activeTerm ? "active" : ""}
                    onClick={() => {
                      onClick({ id, term: null });
                    }}
                  >
                    All
                  </div>
                  {/* <!-- Цифровые значения Signal Type конец --> */}
                </div>
              </div>
            ))}
          {presets &&
            presets.length &&
            presets.map((preset) => (
              <div className="signal_type_item" key={preset.presetId}>
                {/* <!-- Элемент Signal Type с галочкой --> */}
                {/* <!-- Изначально выглядит пассивным. При добавлении класса active становится активным. Галочка становится  --> */}
                <div
                  className={`signal_type_name_box ${
                    preset.presetId.toString() === activeId.toString() ? "active" : ""
                  }`}
                >
                  <span></span>
                  <div className="signal_type_name">{preset.presetName}</div>
                </div>
                {/* <!-- Элемент Signal Type с галочкой конец --> */}
                <div className="signal_type_graph">
                  {/* <!-- Цифровые значения Signal Type --> */}
                  {/* <!-- Изначально все элементы пассивные. При добавлении класса active становится активным. Цифра обводится в круглешок и становится белой  --> */}
                  {preset.expiration
                    .sort((a, b) => a.value - b.value)
                    .map(({ label, value }) => (
                      <div
                        className={
                          preset.presetId.toString() === activeId.toString() && activeTerm === value
                            ? "active"
                            : ""
                        }
                        key={label}
                        onClick={() => {
                          onClick({ id: preset.presetId, term: value });
                        }}
                      >
                        {label}
                      </div>
                    ))}
                  <div
                    className={
                      preset.presetId.toString() === activeId.toString() && !activeTerm ? "active" : ""
                    }
                    onClick={() => {
                      onClick({ id: preset.presetId, term: null });
                    }}
                  >
                    All
                  </div>
                  {/* <!-- Цифровые значения Signal Type конец --> */}
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* <!-- Список тип сигнала конец --> */}
    </>
  );
};

export default View;
