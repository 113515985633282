import { errorInitialSettings, fetchInitialSettings as fetchAction, successInitialSettings } from "./actions";
import { receiveInitialSignals } from "../signals";
import { receiveInitialIndicators } from "../indicators";
import { requestInitialSettings } from "../api";
import { getLicenseKey } from "../mainSettings";
import { updateTms } from "../client";
import { connectSocket } from "../socket";
import { initDashboard } from "../dashboard";
import { fetchProviders, setLoadingOrderPlacement } from "../providers";
import { getSymbolsForChartsThunk } from "../charts/datafeed";
import { clearSavedChartsCacheThunk } from "../charts/thunks";

export const fetchInitialSettings = () => async (dispatch, getState) => {
  dispatch(fetchAction());
  dispatch(setLoadingOrderPlacement(false));
  dispatch(clearSavedChartsCacheThunk());
  const lkey = getLicenseKey(getState());
  try {
    const { data } = await requestInitialSettings({ lkey });
    dispatch(updateTms(data.tms));
    dispatch(successInitialSettings(data));
    dispatch(receiveInitialSignals([...data.signals]));
    dispatch(receiveInitialIndicators([...data.indicators]));
    dispatch(initDashboard([...data?.env_settings?.free_account_assets]));

    dispatch(fetchProviders(data?.account_info?.user_id));

    if (process.env.NODE_ENV === "production" && data?.env_settings?.debug === true) {
      window.Sentry.init({
        dsn: "https://efbfb30eeb0643cf8337eeaf30f96ae4@o348725.ingest.sentry.io/5236143",
      });
      console.log("debug tool connected");
    }

    dispatch(connectSocket());
    dispatch(getSymbolsForChartsThunk());
  } catch (e) {
    console.error(e);
    dispatch(errorInitialSettings());
  }
};
