import React from "react";
import styled from "styled-components";

import ErrorMessage from "../../../../../base/form/ErrorMessage";
import Input from "../../../../../base/form/Input";
import Button from "../../../../../base/form/Button";

import iconLock from "./assets/icon-lock.svg";

const ApiCreds = ({ creds, connectionError, connectionStatus, onCredsChange, onConnect }) => {
  return (
    <Container disabled={connectionStatus}>
      <Title>API credentials</Title>
      <Line>
        {creds.map((input) => (
          <InputWrapper key={input.name}>
            <Input
              disabled={connectionStatus}
              placeholder={input.label}
              value={input.value || ""}
              onChange={(e) => onCredsChange(input.name, e.target.value)}
            />
          </InputWrapper>
        ))}
        <InputWrapper>
          <StyledButton disabled={connectionStatus} onClick={onConnect}>
            Connect
          </StyledButton>
        </InputWrapper>
      </Line>
      {connectionError && (
        <ConnectionErrorWrapper>
          <ErrorMessage errorText={connectionError} />
        </ConnectionErrorWrapper>
      )}
      <DisabledOverlay isActive={connectionStatus}>
        <IconLock src={iconLock} />
      </DisabledOverlay>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  position: relative;
  & div {
    color: ${(props) => (props.disabled ? "rgba(255, 255, 255, 0.17)" : "white")};
  }
`;

const Title = styled.div`
  color: white;
  font-size: 15px;
  font-weight: 500;
  padding-left: 2px;
  margin-bottom: 10px;
`;

const Line = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  margin-right: 10px;
  width: 180px;
`;

const StyledButton = styled(Button)`
  width: 140px;
  height: 35px;
  padding: 10px 20px;
  margin-top: 8px;
  margin-right: 15px;
`;

const ConnectionErrorWrapper = styled.div`
  margin-top: 10px;
`;

const DisabledOverlay = styled.div`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
`;

const IconLock = styled.img`
  position: relative;
  top: -32px;
  width: 18px;
  height: 18px;
`;

export default ApiCreds;
