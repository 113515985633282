import isElectron from "is-electron";

export const openLink = (url, newTabCallback) => {
  if (url.indexOf("defbrowser::") === 0) {
    if (isElectron()) {
      window.shell.openExternal(url.replace("defbrowser::", ""));
    } else {
      window.open(url.replace("defbrowser::", ""), "_blank");
    }
    return;
  }
  newTabCallback(url);
};

export const getSLTP = (strategy, price, atr20, symbolPoint, signal) => {
  const symbol_point = symbolPoint > 0 ? symbolPoint : 0.0001;
  let fixed = Math.abs(Math.log(symbol_point) / Math.log(10));

  const take_profit = (parseFloat(price) + parseFloat(atr20) * strategy.tp * signal).toFixed(fixed);
  const stop_loss = (parseFloat(price) - parseFloat(atr20) * strategy.sl * signal).toFixed(fixed);

  return { take_profit, stop_loss };
};

export const isEqualObjects = (object1, object2) => {
  if (!object1 || !object2) return false;

  const props1 = Object.getOwnPropertyNames(object1);
  const props2 = Object.getOwnPropertyNames(object2);

  if (props1.length !== props2.length) {
    return false;
  }

  for (let i = 0; i < props1.length; i += 1) {
    const prop = props1[i];
    const bothAreObjects = typeof object1[prop] === "object" && typeof object2[prop] === "object";

    if (
      (!bothAreObjects && object1[prop] !== object2[prop]) ||
      (bothAreObjects && !isEqualObjects(object1[prop], object2[prop]))
    ) {
      return false;
    }
  }

  return true;
};
