import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";

const LoadingIndicator = ({ width, height }) => {
  return (
    <Wrapper width={width} height={height}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </Wrapper>
  );
};

LoadingIndicator.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 19px;
  height: 19px;

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    margin: 0px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: ${animation} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export default LoadingIndicator;
