import { WSConnection } from "./wsConnection";
import { getAccountInfoEmail, getQuotesWSChannel, getQuotesWSHost } from "../initial";
import {
  calculatePnlThunk,
  getActiveProvider,
  getProviderContract,
  getProviderOpenOrders,
} from "../providers";
import packageJson from "../../../package.json";
import { setAdditionalQuote } from "../quotes";

let quotesWSConnection;
let chartSymbol;
let chartSymbolInfo;
let chartRealtimeCb;
let chartSymbolChannel = "";

/*
* params
  {
   datafeedData: { symbolInfo; interval; onRealtimeCallback; }
   symbol?: string
  }
* */

export const connectQuotesSocket = (params) => (dispatch, getState) => {
  const state = getState();
  const provider = getActiveProvider(state);
  const { positions } = getProviderOpenOrders(state);

  if (
    params?.datafeedData?.symbolInfo &&
    params?.datafeedData?.interval &&
    params?.datafeedData?.onRealtimeCallback
  ) {
    const { symbolInfo, interval, onRealtimeCallback } = params.datafeedData;
    chartSymbol = symbolInfo.symbol;
    chartSymbolInfo = symbolInfo;
    chartRealtimeCb = onRealtimeCallback;
    let providerName = provider.name.toLowerCase();

    const contract = getProviderContract(state, chartSymbolInfo.symbol);

    if (!contract) {
      providerName = "binance";
    }

    if (provider.type === "forex" && chartSymbolInfo.type === "crypto") {
      providerName = "binance";
    }

    if (provider.type === "crypto" && chartSymbolInfo.type === "forex") {
      providerName = "simplefx";
    }

    chartSymbolChannel = `${providerName}::${chartSymbol}::${interval}`;
  }

  if (quotesWSConnection) {
    quotesWSConnection.removeAllListeners();
    quotesWSConnection.disconnect();
    quotesWSConnection = null;
  }

  const additionalQuotes = [];

  if (provider.name.toLowerCase() === "simplefx") {
    additionalQuotes.push("simplefx::USDJPY::1");
    additionalQuotes.push("simplefx::GBPUSD::1");
  }

  if (params?.symbol) {
    const activeProvider = getActiveProvider(getState());
    additionalQuotes.push(`${activeProvider.name.toLowerCase()}::${params.symbol}::1`);
  }

  quotesWSConnection = new WSConnection({
    email: getAccountInfoEmail(getState()),
    version: packageJson.version,
    build: packageJson.hash,
    platform: window.platform ?? "browser",
    host: getQuotesWSHost(getState()),
    channel: getQuotesWSChannel(getState()),
    assets: [
      chartSymbolChannel,
      ...additionalQuotes,
      ...positions.reduce((res, pos) => {
        if (pos.symbol !== chartSymbol) {
          res.push(`${provider.name.toLowerCase()}::${pos.symbol}::1`);
        }

        return res;
      }, []),
    ],
  });

  quotesWSConnection.subscribe("message", (message) => {
    if (additionalQuotes.includes(`simplefx::${message.symbol}::1`)) {
      dispatch(setAdditionalQuote(message));
    }

    const { open, close, high, low, timeOpen: time, volume } = message;
    if (message.symbol === chartSymbol && chartRealtimeCb) {
      chartRealtimeCb({
        time,
        open,
        close,
        high,
        low,
        volume,
      });
    }

    dispatch(calculatePnlThunk(message));
  });

  // quotesWSConnection.subscribe("error", (error) => console.log(error));
};
