import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { AppTerminal as TerminalApp } from "@xsystems0/xsignals-terminal";
import "@xsystems0/xsignals-terminal/build/index.css";
import "react-day-picker/lib/style.css";

import { getDatafeed, PRICE_SCALE } from "../../modules/charts/datafeed";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersStrategy, getProvidersApiHost } from "../../modules/initial";
import {
  getDashboardScale,
  getLayoutSettings,
  getTerminalSettings,
  setActiveChartTabTitle,
  setChartTabDataThunk,
  setOrdersPanelTableSettings,
  toggleChartPosition,
  toggleLayoutSettings,
  toggleOrdersPanel,
} from "../../modules/client";
import { getSLTP } from "../../utils";
import {
  accountRequestThunk,
  closeAllPositionsThunk,
  getAccountBalance,
  getActiveProvider,
  getIsLoadingOrderPlacement,
  getIsOrderHistoryLoading,
  getOrdersHistory,
  getProviderOpenOrders,
  getProviders,
  getProviderToken,
  getSelectedOption,
  placeOrderThunk,
} from "../../modules/providers";
import {
  requestProviderCancelOrder,
  requestProviderClosePosition,
  requestProviderModifyOrder,
} from "../../modules/providersApi";
import useDebounce from "../../hooks/useDebounce";
import getPlayerDatafeed from "../../modules/charts/playerDatafeed";
import chartEventEmitter from "../../modules/charts/utils/chartEventEmitter";

const AppTerminal = (props) => {
  const dispatch = useDispatch();

  const datafeed = useRef(getDatafeed(dispatch));

  useSelector(getProviders);
  const activeProvider = useSelector(getActiveProvider);
  const ordersStrategy = useSelector(getOrdersStrategy);
  const token = useSelector(getProviderToken);
  const selectedOptions = useSelector(getSelectedOption);
  const openOrders = useSelector(getProviderOpenOrders);
  const terminalSettings = useSelector(getTerminalSettings);
  const accountBalance = useSelector(getAccountBalance);
  const layoutSettings = useSelector(getLayoutSettings);
  const ordersHistory = useSelector(getOrdersHistory);
  const isOrderHistoryLoading = useSelector(getIsOrderHistoryLoading);
  const isPlaceOrderLoading = useSelector(getIsLoadingOrderPlacement);
  const providerServiceHost = useSelector(getProvidersApiHost);
  const dashboardPanelScale = useSelector(getDashboardScale);

  const [CLDatafeed, setCLDatafeed] = useState(datafeed.current);
  const [chartPlayerDatafeed, setChartPlayerDatafeed] = useState(null);
  const [chartPlayerData, setChartPlayerData] = useState(null);

  const isShowDashboardPanel = layoutSettings.settingsIndex === 1 || layoutSettings.settingsIndex === 2;

  useEffect(() => {
    dispatch(accountRequestThunk());
    dispatch(setActiveChartTabTitle({ id: props.id, title: props.symbol }));
  }, [props.symbol]);

  useEffect(() => {
    setChartPlayerData(null);
    setCLDatafeed(datafeed.current);
    if (terminalSettings.showOrdersPanel) {
      dispatch(toggleLayoutSettings({ ...layoutSettings, dashboardPanel: false }));
    }

    return () => {
      if (isShowDashboardPanel) {
        dispatch(toggleLayoutSettings({ ...layoutSettings, dashboardPanel: true }));
      }
    };
  }, [dispatch, props.symbol, terminalSettings]);

  const debounceAccountRequest = useDebounce((startTime, endTime) => {
    dispatch(accountRequestThunk(startTime, endTime));
  }, 1500);

  const validate = (value) => {
    const parts = value.toString().split(".");
    const maxSymbols = PRICE_SCALE.toString().length - 1;
    if (parts.length > 1 && parts[1].length > maxSymbols) {
      value = parseFloat(value).toFixed(maxSymbols);
    }

    return value;
  };

  const handleToggleChartPosition = () => {
    dispatch(toggleChartPosition());
  };

  const handleToggleOrdersPanel = () => {
    if (isShowDashboardPanel) {
      dispatch(
        toggleLayoutSettings({ ...layoutSettings, dashboardPanel: !terminalSettings.showOrdersPanel })
      );
    }
    dispatch(toggleOrdersPanel());
  };

  const handleTPChange = (value) => {
    const validValue = validate(value);

    dispatch(setChartTabDataThunk({ id: props.id, data: { ...props, take_profit: validValue } }));
  };

  const handleSLChange = (value) => {
    const validValue = validate(value);

    dispatch(setChartTabDataThunk({ id: props.id, data: { ...props, stop_loss: validValue } }));
  };

  const handleSlTpClear = () => {
    dispatch(setChartTabDataThunk({ id: props.id, data: { ...props, stop_loss: "", take_profit: "" } }));
  };

  const handlePriceChange = (value) => {
    const validValue = validate(value);

    dispatch(setChartTabDataThunk({ id: props.id, data: { ...props, price: validValue } }));
  };

  const handleStrategyChange = (strategy) => {
    if (typeof props.symbol_point !== "number" || !props.atr20 || !props.price) return;

    const { take_profit, stop_loss } = getSLTP(
      strategy,
      props.price,
      props.atr20,
      props.symbol_point,
      props.signal
    );

    dispatch(setChartTabDataThunk({ id: props.id, data: { ...props, take_profit, stop_loss, strategy } }));
  };

  const handleSizeChange = (value) => {
    dispatch(setChartTabDataThunk({ id: props.id, data: { ...props, size: value } }));
  };

  const handleSymbolChanged = (symbol, price) => {
    dispatch(setChartTabDataThunk({ id: props.id, data: { symbol, term: props.term, price } }));
    dispatch(accountRequestThunk());
  };

  const handleIntervalChanged = (interval) => {
    dispatch(setChartTabDataThunk({ id: props.id, data: { ...props, term: interval } }));
  };

  const handleSideChange = (signal) => {
    const { strategy, symbol_point, atr20, price } = props;

    const { take_profit, stop_loss } = getSLTP(strategy, price, atr20, symbol_point, signal);

    dispatch(setChartTabDataThunk({ id: props.id, data: { ...props, signal, take_profit, stop_loss } }));
  };

  const handlePlaceOrder = (data) => {
    const { symbol, orderType, side, stopLoss, takeProfit, volume, limitPrice, chartPlayerData } = data;
    dispatch(
      placeOrderThunk({
        symbol,
        orderType,
        direction: side,
        tpPrice: takeProfit,
        slPrice: stopLoss,
        volume,
        limitPrice: parseFloat(limitPrice),
        price: props.price,
        chartPlayerData,
      })
    );
  };

  const handleClosePosition = async (orderId, symbol) => {
    // dispatch(requestClosePositionThunk(orderId));

    let order;

    if (orderId) {
      order = openOrders.positions.find((position) => position.orderId === orderId);
    } else {
      order = openOrders.positions.find((position) => position.symbol === symbol);
    }

    const size = order?.size;
    const closeDirection = order?.side === 1 ? -1 : 1;

    return requestProviderClosePosition(
      dispatch,
      token,
      orderId,
      symbol,
      size,
      closeDirection,
      selectedOptions.value,
      activeProvider.name,
      providerServiceHost
    );
  };

  const handleCloseAllPositions = () => {
    dispatch(closeAllPositionsThunk());
  };

  const handleCancelOrder = async (orderId, symbol, type) => {
    // dispatch(requestCancelOrderThunk(orderId, type));

    const slOrder = openOrders.orders.find((order) => order.type === "stopLoss" && order.symbol === symbol);
    const tpOrder = openOrders.orders.find((order) => order.type === "takeProfit" && order.symbol === symbol);

    const slPrice = type === "takeProfit" ? slOrder?.price : null;
    const tpPrice = type === "stopLoss" ? tpOrder?.price : null;
    const closeDirection = slOrder?.side || tpOrder?.side;
    const volume = slOrder?.volume || tpOrder?.volume;

    return requestProviderCancelOrder(
      dispatch,
      token,
      symbol,
      orderId,
      type,
      slPrice,
      tpPrice,
      closeDirection,
      volume,
      selectedOptions.value,
      activeProvider.name,
      providerServiceHost
    );
  };

  const handleModifyOrder = async (orderId, symbol, type, price) => {
    // dispatch(requestModifyOrderThunk(orderId, type, volume));

    const position = openOrders.positions.find((pos) => pos.symbol === symbol);

    const slOrder = openOrders.orders.find(
      (stopOrder) => stopOrder.type === "stopLoss" && stopOrder.symbol === symbol
    );
    const tpOrder = openOrders.orders.find(
      (stopOrder) => stopOrder.type === "takeProfit" && stopOrder.symbol === symbol
    );

    const volume = position.size;
    const closeDirection = position.side === 1 ? -1 : 1;

    const slPrice = type === "stopLoss" ? price : slOrder?.price;
    const tpPrice = type === "takeProfit" ? price : tpOrder?.price;

    return requestProviderModifyOrder(
      dispatch,
      token,
      orderId,
      symbol,
      type,
      volume,
      closeDirection,
      slPrice,
      tpPrice,
      selectedOptions.value,
      activeProvider.name,
      providerServiceHost
    );
  };

  const handleChangeOrdersPanelTableSettings = (settings) => {
    dispatch(setOrdersPanelTableSettings(settings));
  };

  const handleRequestOrdersHistory = (startTime, endTime) => {
    debounceAccountRequest(startTime, endTime);
  };

  const handlePlayChart = (playerData) => {
    const playerDatafeed = getPlayerDatafeed(
      dispatch,
      playerData.chartData.fromTime,
      playerData.chartData.symbol,
      playerData.chartData.type,
      playerData.chartData.side,
      playerData.chartData.stopLoss,
      playerData.chartData.takeProfit,
      playerData.chartData.closeTime
    );

    setChartPlayerData(playerData);
    setChartPlayerDatafeed(playerDatafeed);
    dispatch(setActiveChartTabTitle({ id: props.id, title: `${playerData.chartData.symbol} (Player)` }));
  };

  const handleChartPlayerClose = () => {
    setChartPlayerData(null);
    setChartPlayerDatafeed(null);
    dispatch(setActiveChartTabTitle({ id: props.id, title: props.symbol }));
  };

  return (
    <Container isActive={props.isActive}>
      <TerminalApp
        datafeed={CLDatafeed}
        chartPlayerDatafeed={chartPlayerDatafeed}
        chartPlayerData={chartPlayerData}
        symbol={props.symbol}
        signalTimestamp={props.timestamp}
        currency={accountBalance.currency}
        balance={accountBalance.balance}
        providerPositionSizeUnitLabel={activeProvider.fields.ordersPositionSize.unit.label}
        openOrders={openOrders}
        interval={props.term.toString()}
        priceScale={PRICE_SCALE}
        side={props.signal || 1}
        size={props.size}
        price={props.price}
        takeProfit={props.take_profit}
        stopLoss={props.stop_loss}
        strategy={props.strategy || null}
        ordersPanelScale={dashboardPanelScale}
        ordersStrategy={ordersStrategy}
        ordersHistory={ordersHistory}
        ordersPanelTableSettings={terminalSettings.ordersPanelTableSettings}
        isShowChartPosition={terminalSettings.chartShowPosition}
        isShowOrdersPanel={terminalSettings.showOrdersPanel}
        isOrderHistoryLoading={isOrderHistoryLoading}
        isPlaceOrderLoading={isPlaceOrderLoading}
        onRequestOrdersHistory={handleRequestOrdersHistory}
        onChangeOrdersPanelTableSettings={handleChangeOrdersPanelTableSettings}
        onToggleChartPosition={handleToggleChartPosition}
        onToggleOrdersPanel={handleToggleOrdersPanel}
        onSizeChange={handleSizeChange}
        onStrategyChange={handleStrategyChange}
        onTPChange={handleTPChange}
        onSLChange={handleSLChange}
        onClearSlTp={handleSlTpClear}
        onPriceChange={handlePriceChange}
        onSymbolChange={handleSymbolChanged}
        onIntervalChange={handleIntervalChanged}
        onSideChange={handleSideChange}
        onPlaceOrder={handlePlaceOrder}
        onClosePosition={handleClosePosition}
        onCloseAllPositions={handleCloseAllPositions}
        onModifyOrder={handleModifyOrder}
        onCancelOrder={handleCancelOrder}
        chartPlayerEventEmitter={chartEventEmitter}
        onPlayChart={handlePlayChart}
        onChartPlayerClose={handleChartPlayerClose}
      />
    </Container>
  );
};

AppTerminal.propTypes = {};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export default AppTerminal;
