import React from "react";
import styled from "styled-components";

import { ReactComponent as IndicatorIcon } from "./assets/indicator-icon.svg";
import { ReactComponent as TimerIcon } from "./assets/timer-icon.svg";
import { ReactComponent as GraphIcon } from "./assets/graph-icon.svg";
import { useSelector } from "react-redux";
import { getCurrentTms } from "../../../../modules/client";

const Meta = ({ period, algName, signalTms }) => {
  return (
    <MetaContainer>
      <MetaTimerItem signalTms={signalTms} />
      <MetaItem>
        <GraphIcon width={"13px"} height={"13px"} fill="#91949b" />
        <MetaLabel>{period} min</MetaLabel>
      </MetaItem>
      <MetaItem>
        <IndicatorIcon width={"13px"} height={"13px"} />
        <MetaLabel>{algName}</MetaLabel>
      </MetaItem>
    </MetaContainer>
  );
};

const MetaTimerItem = ({ signalTms }) => {
  const currentTms = useSelector(getCurrentTms);

  const updatedServerSync = new Date(currentTms - signalTms * 1000).toISOString().substr(14, 5);

  return (
    <MetaItem>
      <TimerIcon width={"13px"} height={"13px"} />
      <MetaLabel>{updatedServerSync}</MetaLabel>
    </MetaItem>
  );
};

const MetaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-content: center;
`;
const MetaItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 15px;
`;
const MetaLabel = styled.div`
  margin-left: 3px;
  color: #91949b;
  font-weight: 500;
  font-size: 9px;
`;

export default Meta;
