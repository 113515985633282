import { NotificationManager } from "react-notifications";

import chartEventEmitter from "./utils/chartEventEmitter";
import ChartPlayer from "./utils/ChartPlayer";
import { PRICE_SCALE } from "./datafeed";
import { getBarsHistoryThunk } from "../providers";

const configurationData = {
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: true,
  supported_resolutions: ["5", "15", "60", "240"],
};

let timeoutId;

const notificate = () => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  timeoutId = setTimeout(() => {
    NotificationManager.error("Failed to load history data");
  }, 1500);
};

const getPlayerDatafeed = (dispatch, fromTime, symbol, type, side, stopLoss, takeProfit, closeTime) => {
  return {
    onReady: (callback) => {
      setTimeout(() => callback(configurationData));
    },

    searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {},

    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
      setTimeout(() => {
        onSymbolResolvedCallback({
          name: symbol,
          symbol,
          full_name: symbol,
          description: symbol,
          exchange: type === "crypto" ? "crypto" : "forex",
          ticker: symbol,
          pricescale: PRICE_SCALE,
          minmov: 1,
          session: type === "crypto" ? "24x7" : "0000-0000",
          type: type,
          timezone: "UTC",
          has_intraday: true,
          has_daily: true,
          has_weekly_and_monthly: true,
          currency_code: "USD",
        });
      });
    },

    // get historical data for the symbol
    getBars: async (symbolInfo, interval, _periodParams, _onHistoryCallback, onErrorCallback) => {
      const from = fromTime - parseInt(interval) * 1000 * 60;
      const to = fromTime;
      const periodParams = {
        from,
        to,
        countBack: 0,
        firstDataRequest: false,
      };

      if (!_periodParams.firstDataRequest) {
        _onHistoryCallback([], { noData: true });
      }

      const onHistoryCallback = (data) => {
        const history = data.filter((bar) => bar.time <= fromTime * 1000);
        if (!history.length) {
          notificate();
          return;
        }
        _onHistoryCallback(history, { noData: true });
      };

      dispatch(
        getBarsHistoryThunk({ symbolInfo, interval, periodParams, onHistoryCallback, onErrorCallback })
      );
    },
    // subscription to real-time updates
    subscribeBars: async (symbolInfo, interval, onRealtimeCallback) => {
      let history = [];

      const periodParams = {
        from: fromTime,
        to: Date.now() / 1000,
        countBack: 0,
        firstDataRequest: false,
      };

      const onHistoryCallback = (historyBars) => {
        history = historyBars.filter((bar) => bar.time > fromTime * 1000);
      };
      const onErrorCallback = (error) => {};

      dispatch(
        getBarsHistoryThunk({ symbolInfo, interval, periodParams, onHistoryCallback, onErrorCallback })
      );

      const intervalId = setInterval(async () => {
        if (history.length) {
          clearInterval(intervalId);
          if (type !== "forex") {
            history = history.filter((bar) => bar.time > fromTime * 1000);
          }

          const chartPlayer = new ChartPlayer({
            historyBars: history,
            onRealtimeCallback,
            side,
            takeProfit,
            stopLoss,
            closeTime,
            chartEventEmitter,
          });

          chartEventEmitter.on("speedChange", (speed) => {
            chartPlayer.changeSpeed(speed);
          });
          chartEventEmitter.on("pause", () => {
            chartPlayer.pause();
          });
          chartEventEmitter.on("play", () => {
            chartPlayer.play();
          });
          chartEventEmitter.on("close", () => {
            chartEventEmitter.removeAllListeners();
          });
        }
      }, 1000);
    },
    unsubscribeBars: (subscriberUID) => {},
    getServerTime: (callback) => {
      callback(Math.floor(Date.now() / 1000));
    },
  };
};

export default getPlayerDatafeed;
