import { createAction } from "redux-actions";

export const newTab = createAction("TABS_PANEL/NEW_TAB");
export const closeTab = createAction("TABS_PANEL/CLOSE_TAB");
export const setActiveTab = createAction("TABS_PANEL/SET_ACTIVE_TAB");
export const setTabTitle = createAction("TABS_PANEL/SET_TAB_TITLE");
export const newChartTab = createAction("TABS_PANEL/NEW_CHART_TAB");

export const setChartTabData = createAction("TABS_PANEL/SET_CHART_TAB_DATA");
export const setChartTabStrategy = createAction("TABS_PANEL/SET_CHART_TAB_STRATEGY");
export const setChartTabs = createAction("TABS_PANEL/SET_CHART_TABS");
export const setActiveChartTabTitle = createAction("TABS_PANEL/SET_ACTIVE_CHART_TAB_TITLE");

export const toggleChartPosition = createAction("TERMINAL_SETTINGS/TOGGLE_SHOW_CHART_POSITION");
export const toggleOrdersPanel = createAction("TERMINAL_SETTINGS/TOGGLE_SHOW_ORDERS_PANEL");
export const setOrdersPanelTableSettings = createAction("TERMINAL_SETTINGS/SET_ORDERS_PANEL_TABLE_SETTINGS");

export const setActiveSignalsType = createAction("LEFT_PANEL_SIGNALS_TYPE/SET_ACTIVE");
export const setActiveBroker = createAction("LEFT_PANEL_BROKER_SETTINGS/SET_ACTIVE");

export const toggleLayoutSettings = createAction("LAYOUT/TOGGLE_SETTINGS");
export const setDashboardScale = createAction("CLIENT/SET_DASHBOARD_SCALE");

export const updateTms = createAction("CLIENT/UPDATE_TMS");
export const updateTmsShift = createAction("CLIENT/UPDATE_TMS_SHIFT");

export const setSignalView = createAction("CLIENT/SET_SIGNAL_VIEW");
