import React, { Component } from "react";
import PropTypes from "prop-types";
import VersionInfo from "./items/VersionInfo";
import AppSettings from "./items/AppSettings";
import Initial from "./items/Initial";

export default class PopupWindows extends Component {
  static propTypes = {
    // showVersionInfo: PropTypes.bool.isRequired,
    // showAppSettings: PropTypes.bool.isRequired,
    appSettings: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
    versionInfo: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
  };

  render() {
    const { versionInfo, appSettings } = this.props;

    return (
      <>
        <VersionInfo {...versionInfo} />
        <AppSettings {...appSettings} />
        <Initial />
      </>
    );
  }
}
