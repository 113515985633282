import React, { Component } from "react";
import { connect } from "react-redux";
import isElectron from "is-electron";
import View from "./View";
import { getAccountInfoLinks } from "../../../../modules/initial";

class VersionInfo extends Component {
  openLink = (url) => {
    if (url.indexOf("defbrowser::") === 0) {
      if (isElectron()) {
        // console.log(window.shell);
        window.shell.openExternal(url.replace("defbrowser::", ""));
      } else {
        window.open(url.replace("defbrowser::", ""), "_blank");
      }
      return;
    }
  };

  render() {
    return <View openLink={this.openLink} {...this.props.links} isActive={this.props.isActive} />;
  }
}

export default connect((state) => ({
  links: getAccountInfoLinks(state),
}))(VersionInfo);
