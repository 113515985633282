import axios from "axios";
import params from "../config";
import packageJson from "../../package.json";
import isElectron from "is-electron";

const { apiHost, appEnvType } = params;

export const requestInitialSettings = ({ lkey, login, password }) => {
  let data = {
    version: packageJson.version,
    build: packageJson.hash,
    platform: isElectron() ? "desktop" : "web",
    env_type: appEnvType,
  };
  if (login && login.length > 0) {
    data = { ...data, login, pwd: password };
  } else {
    data = { ...data, lkey };
  }

  return axios({
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
    },
    url: `${apiHost}/proxy/initial`,
    data,
  });
};
