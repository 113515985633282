import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  authProvider,
  createSelectOption,
  deleteAccountDataThunk,
  getActiveProvider,
  getApplySettingsError,
  getConnectionError,
  getConnectionStatus,
  getHowToConnectLink,
  getOrdersPositionSize,
  getProviders,
  getQuickBuyStatus,
  getSelectCreatableStatus,
  getSelectData,
  getSelectedOption,
  // setAccountData,
  setActiveProviderThunk,
  setApplySettingsErrorThunk,
  setOrdersPositionSize,
  setSelectOption,
  toggleQuickBuy,
} from "../../../../../../modules/providers";

import View from "./View";

// TODO: переписать форму с использованием Formik

const IntegrationsTab = () => {
  const dispatch = useDispatch();
  const providers = useSelector(getProviders);
  const activeProvider = useSelector(getActiveProvider);
  const ordersPositionSize = useSelector(getOrdersPositionSize);
  const connectionStatus = useSelector(getConnectionStatus);
  const selectOptions = useSelector(getSelectData);
  const selected = useSelector(getSelectedOption);
  const quickBuyStatus = useSelector(getQuickBuyStatus);
  const connectionError = useSelector(getConnectionError);
  const applySettingsError = useSelector(getApplySettingsError);
  const isSelectCreatable = useSelector(getSelectCreatableStatus);
  const howToConnectLink = useSelector(getHowToConnectLink);

  const [creds, setCreds] = useState([]);
  const [ordersPositionSizeState, setOrdersPositionSizeState] = useState(
    ordersPositionSize?.map((position) => ({
      value: position,
      error: null,
    })) || []
  );

  useEffect(() => {
    if (activeProvider) {
      setCreds([...activeProvider?.fields.creds]);
    }
  }, []);

  useEffect(() => {
    if (activeProvider) {
      setCreds([...activeProvider?.fields.creds]);
      setOrdersPositionSizeState(
        ordersPositionSize.map((position) => ({
          value: position,
          error: null,
        }))
      );
    }
  }, [activeProvider, ordersPositionSize, setCreds, setOrdersPositionSizeState]);

  if (!activeProvider) return null;

  const handleCredsChange = (name, value) => {
    setCreds((prev) => {
      return prev.map((input) => {
        input.value = input.name === name ? value : input.value;

        return input;
      });
    });
  };

  const handleOrdersPosSizeChange = (index, value) => {
    if (!/^[0-9]*\.?[0-9]*$/g.test(value)) return;

    let error = null;

    const { min, max } = activeProvider.fields.ordersPositionSize.rangeValues;

    if (value < min) {
      error = `min value - ${min}`;
    }

    if (value > max) {
      error = `max value - ${max}`;
    }

    setOrdersPositionSizeState((prev) => {
      return prev.map((position, i) => {
        if (i === index) {
          position.value = value;
          position.error = error;
        }
        return position;
      });
    });

    if (!error) {
      dispatch(setOrdersPositionSize({ index, value }));
    }
  };

  const handleProviderChange = (provider) => {
    dispatch(setActiveProviderThunk(provider.name));
  };

  const handleConnect = () => {
    let data;
    try {
      data = creds.reduce((res, current) => {
        if (!current.value) return null;
        res[current.name] = current.value;
        return res;
      }, {});

      data.provider = activeProvider.name.toLowerCase();

      dispatch(authProvider(data));
    } catch (e) {
      return;
    }
  };

  const handleSelectChange = (option) => {
    dispatch(setSelectOption(option.label));
  };

  const handleToggleQuickBuy = () => {
    dispatch(toggleQuickBuy());
  };

  const handleApply = () => {
    if (!selected) {
      dispatch(setApplySettingsErrorThunk(`Please select ${activeProvider.fields.select.name}`));
      return;
    }
    // dispatch(
    //   setAccountData({
    //     [activeProvider.fields.select.name]: selected.value,
    //     provider: activeProvider.name,
    //   })
    // );
  };

  const handleDeleteAccountData = (provider) => {
    dispatch(deleteAccountDataThunk(provider));
  };

  const handleCreateSelectOption = (value) => {
    dispatch(createSelectOption(value));
  };

  const data = {
    providers,
    activeProvider,
    creds,
    connectionStatus,
    selectOptions,
    selected,
    ordersPositionSize: ordersPositionSizeState,
    quickBuyStatus,
    connectionError,
    applySettingsError,
    isSelectCreatable,
  };

  return (
    <View
      {...data}
      howToConnectLink={howToConnectLink}
      onProviderChange={handleProviderChange}
      onCredsChange={handleCredsChange}
      onSelectChange={handleSelectChange}
      onSelectCreateOption={handleCreateSelectOption}
      onPositionSizeChange={handleOrdersPosSizeChange}
      onConnect={handleConnect}
      onToggleQuickBuy={handleToggleQuickBuy}
      onApply={handleApply}
      onDeleteAccount={handleDeleteAccountData}
    />
  );
};

export default IntegrationsTab;
