import {
  newChartTab,
  newTab as newTabState,
  setActiveSignalsType as setActiveSignalsTypeState,
  setActiveTab,
  setChartTabData,
  setChartTabs,
  updateTms as updateTmsState,
  updateTmsShift,
} from "./actions";
import { getChartTabs, getTabs, getTerminalSettings } from "./selector";

let tmsShiftInterval;

export const setActiveSignalsType = (payload) => (dispath, getState) => {
  dispath(setActiveSignalsTypeState(payload));
};

export const newTab = (payload) => (dispath, getState) => {
  let tabIsSet = getTabs(getState()).find(({ url }) => payload === url);
  if (!tabIsSet) {
    dispath(newTabState(payload));
    tabIsSet = getTabs(getState()).find(({ url }) => payload === url);
  }

  dispath(setActiveTab(tabIsSet.id));
};

export const newChartTabThunk = (payload) => (dispatch, getState) => {
  const { symbol, term } = payload;
  const chartTabs = getChartTabs(getState());
  const terminalSettings = getTerminalSettings(getState());
  const tabId = symbol + term;

  if (!terminalSettings.openOnNewWindow) {
    dispatch(setChartTabs([{ id: tabId, ...payload }]));
    dispatch(setActiveTab(tabId));
    return;
  }

  const isExist = chartTabs.find((chartTab) => chartTab.symbol === symbol && chartTab.term === term);

  if (isExist) {
    dispatch(setActiveTab(isExist.id));
    return;
  }

  dispatch(newChartTab({ id: tabId, ...payload }));
  dispatch(setActiveTab(tabId));
};

export const setChartTabDataThunk = (payload) => (dispatch, getState) => {
  const chartTabs = getChartTabs(getState());
  const tabId = payload.data.symbol + payload.data.term;

  const isExist = chartTabs.find((tab) => tab.id === tabId);

  if (!!isExist) {
    dispatch(setChartTabData({ id: tabId, data: { ...payload.data, id: tabId } }));
  } else {
    dispatch(setChartTabData({ id: payload.id, data: { ...payload.data, id: tabId } }));
  }

  dispatch(setActiveTab(tabId));
};

export const updateTms = (payload) => (dispath, getState) => {
  clearInterval(tmsShiftInterval);
  tmsShiftInterval = setInterval(() => {
    dispath(updateTmsShift());
  }, 1000);

  dispath(updateTmsState(payload));
};
