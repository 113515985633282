import React from "react";

const View = ({ children }) => {
  return (
    <div className="graph_box">
      {/* <!-- Вкладки, над графиком --> */}
      {children}
      {/* <!-- Вкладки, над графиком конец --> */}
    </div>
  );
};

export default View;