import {
  setLicenseKey as setLicenseKeyState,
  setClientPRO,
  setLicenseKeyError,
  setClientFree,
} from "./actions";
// import { updateSignalsSettings, updateIndicatorsSettings } from "../socket";
import { requestInitialSettings } from "../api";
import { successInitialSettings } from "../initial";
import { receiveInitialSignals } from "../signals";
import { receiveInitialIndicators } from "../indicators";
import { fetchProviders } from "../providers";

export const setLicenseKey = (payload) => async (dispatch, getState) => {
  dispatch(setLicenseKeyError(false));
  let setError = true;
  try {
    const { data } = await requestInitialSettings({ ...payload });

    const isValid = !!data?.account_info?.user_id;
    console.log("isValid", isValid);

    dispatch(successInitialSettings(data));
    dispatch(receiveInitialSignals([...data.signals]));
    dispatch(receiveInitialIndicators([...data.indicators]));
    dispatch(fetchProviders(data?.account_info?.user_id));

    if (isValid && isValid === true) {
      setError = false;
      dispatch(setLicenseKeyState(data.lkey));

      if (data.account_info.license.toLowerCase() === "pro") {
        dispatch(setClientPRO());
      } else {
        dispatch(setClientFree());
      }
    }
  } catch (e) {
    console.log("Auth error");
  }

  if (setError) {
    dispatch(setLicenseKeyError(true));
    setTimeout(() => {
      dispatch(setLicenseKeyError(false));
    }, 5000);
  }

  // dispatch(updateSignalsSettings());
  // dispatch(updateIndicatorsSettings());
};
