import { useSelector } from "react-redux";
import styled from "styled-components";
import { getActiveProvider, getOrdersPositionSize } from "../../../../../modules/providers";
import OrderButton from "./OrderButton/OrderButton";

const colors = {
  1: {
    main: "#9FE6A0",
    hover: "#77de79",
  },
  "-1": {
    main: "#E9655B",
    hover: "#EB4D42",
  },
  blocked: {
    main: "#54575E",
    hover: "#54575E",
  },
};

const formatNumber = (num) => {
  const numStr = num.toString();
  if (parseInt(numStr[numStr.length - 1]) > 0) {
    return num;
  }
  return num > 999 ? num / 1000 + "k" : num;
};

const SignalMenu = ({
  active,
  showFull,
  side,
  orderStrategyId,
  orderStrategyList,
  orderStrategyProfitList,
  isLoadingOrderPlacement,
  onPlaceOrder,
  onChangeStrategy,
}) => {
  const ordersPosition = useSelector(getOrdersPositionSize);
  const activeProvider = useSelector(getActiveProvider);

  if (!ordersPosition || !ordersPosition.length) return null;

  return (
    <Container active={active} id={"signal-menu"}>
      <OrderButtonsContainer>
        {ordersPosition.map((position, index) => (
          <OrderButton
            key={index}
            disabled={isLoadingOrderPlacement}
            loading={isLoadingOrderPlacement}
            colors={colors[showFull ? side : "blocked"]}
            onPlaceOrder={() => onPlaceOrder({ volume: position })}
          >
            {activeProvider.fields.ordersPositionSize.unit.short}
            {formatNumber(position)}
          </OrderButton>
        ))}
      </OrderButtonsContainer>
      <OrdersStrategyContainer>
        {orderStrategyList &&
          orderStrategyList.map((strategy, index) => (
            <OrderStrategy
              showFull={showFull}
              key={index}
              active={orderStrategyId === strategy.id}
              onClick={() => onChangeStrategy(strategy)}
            >
              <StrategyTop>
                {strategy.tp}/{strategy.sl}
              </StrategyTop>
              <Divider />
              <StrategyBottom>{orderStrategyProfitList[index]}</StrategyBottom>
            </OrderStrategy>
          ))}
      </OrdersStrategyContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  transform: ${(props) => (props.active ? "translateY(0)" : "translateY(-100px)")};
  z-index: -5;
  transition-property: height;
  transition: all 0.2s ease-in-out;
  max-height: ${(props) => (props.active ? "auto" : "0px")};
  padding: 10px;
  overflow: hidden;
  background-color: #262b3b;
  margin: 0 0 5px;
  border-radius: 0 0 3px 3px;
`;

const OrderButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
  & button {
    margin-right: 4px;
  }
  & button:nth-last-child(1) {
    margin-right: 0;
  }
`;

const OrdersStrategyContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OrderStrategy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 3px;
  color: ${(props) => (props.showFull ? "white" : "#8C8E95")};
  border: ${(props) => {
    if (!props.showFull) {
      return "1px solid #1e2432";
    }
    return props.active ? "1px solid #66E3FF" : "1px solid #11141f";
  }};
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 10px;
  padding: 4px 0px;
  width: 29px;
  &:hover {
    cursor: pointer;
  }
`;

const StrategyTop = styled.span`
  opacity: 0.4;
`;

const StrategyBottom = styled.span``;

const Divider = styled.hr`
  width: 90%;
  border-bottom: 1px;
  border-color: #1b1f2c;
`;

export default SignalMenu;
