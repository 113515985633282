import { confirmAlert } from "@xsystems0/xsignals-terminal";
import {
  setAccountData,
  setActiveProvider,
  setApplySettingsError,
  setConnectionError,
  setConnectionStatus,
  setIsOrdersHistoryLoading,
  setLoadingOrderPlacement,
  setOpenOrders,
  setOrdersHistory,
  setPositionPnl,
  setProviderIntegrations,
  setProviders,
  setProviderToken,
  setSelectData,
} from "./actions";
import { getAccountInfoLkey } from "../initial";
import {
  getActiveProvider,
  getProviderContract,
  getProviderOpenOrders,
  getProviders,
  getProviderToken,
  getQuickBuyStatus,
  getSelectedOption,
} from "./selectors";
import { getAccountInfoLicense, getAccountUserId, getAssets, getProvidersApiHost } from "../initial";
import { NotificationManager } from "react-notifications";
import { _requestProvider } from "../providersApi";
import { connectQuotesSocket } from "../socket/quotesWS";
import { getAllAdditionalQuotes } from "../quotes";
import { saveChart } from "../charts";

export const fetchProviders = (vfxUserId) => async (dispatch, getState) => {
  if (!vfxUserId) return;
  const state = getState();
  const host = getProvidersApiHost(state);
  const lkey = getAccountInfoLkey(state);

  try {
    const response = await _requestProvider({
      method: "GET",
      host,
      path: `/list?vfxUserId=${vfxUserId}&lkey=${lkey}`,
    });

    if (response.status === 200) {
      dispatch(setProviders(response.data.providers));
      dispatch(setProviderIntegrations(response.data.providerIntegrations));
      dispatch(accountRequestThunk());
    }
  } catch (e) {
    console.error(e);
  }
};

export const authProvider = (data) => async (dispatch, getState) => {
  const state = getState();
  const vfxUserId = getAccountUserId(state);
  const lkey = getAccountInfoLkey(state);
  const host = getProvidersApiHost(getState());

  try {
    const response = await _requestProvider({
      method: "POST",
      path: "/auth",
      host,
      params: { ...data, vfxUserId, lkey },
    });

    if (!response.data.errorCode) {
      const { token, formFields } = response.data;

      dispatch(setProviderToken({ token, provider: data.provider.toLowerCase() }));
      dispatch(setSelectData({ providerName: data.provider, select: formFields.select.defaults }));
      dispatch(setConnectionStatus({ providerName: data.provider, status: true }));
      dispatch(accountRequestThunk());
    } else {
      dispatch(setConnectionErrorThunk("An error occured, please try again later"));
    }
  } catch (e) {
    if (e.response?.data?.error) {
      dispatch(setConnectionErrorThunk(e.response.data?.message));
    } else {
      dispatch(setConnectionErrorThunk("An error occured, please try again later"));
    }
  }
};

export const setActiveProviderThunk = (payload) => (dispatch, getState) => {
  const state = getState();
  const providers = getProviders(state);
  const newActive = providers.find((provider) => provider.name === payload);

  dispatch(setActiveProvider(payload));
  dispatch(connectQuotesSocket());

  if (newActive.connectionStatus) {
    dispatch(accountRequestThunk());
  }
};

export const accountRequestThunk =
  (historyStartTime = undefined, historyEndTime = undefined) =>
  async (dispatch, getState) => {
    const providerOptions = getSelectedOption(getState());
    const token = getProviderToken(getState());
    const host = getProvidersApiHost(getState());
    const provider = getActiveProvider(getState());

    if (!provider.connectionStatus) return;

    if (!providerOptions || !providerOptions.value) {
      // NotificationManager.error(
      //   "Account request error. Please select account options in settings",
      //   provider.name
      // );
      return;
    }

    const reqParams = {
      options: providerOptions ? encodeURI(JSON.stringify(providerOptions.value)) : "",
      historyStartTime,
      historyEndTime,
    };

    try {
      dispatch(setIsOrdersHistoryLoading(true));
      const response = await _requestProvider({
        method: "GET",
        path: "/account",
        token,
        host,
        params: reqParams,
      });
      dispatch(setIsOrdersHistoryLoading(false));

      if (response?.data?.errorCode === 0) {
        const { positions, orders, currency, balance, contracts, ordersHistory } = response.data.account;
        dispatch(setOpenOrdersThunk({ positions, orders }, true));
        dispatch(setOrdersHistory(ordersHistory));
        dispatch(setAccountData({ currency, balance, contracts }));
      }
    } catch (e) {
      dispatch(setIsOrdersHistoryLoading(false));
      console.error(e);
    }
  };

export const requestOrdersHistoryThunk = (startTime, endTime) => async (dispatch, getState) => {
  const state = getState();
  const token = getProviderToken(state);
  const options = getSelectedOption(state);
  const host = getProvidersApiHost(getState());

  try {
    dispatch(setIsOrdersHistoryLoading(true));
    const response = await _requestProvider({
      method: "POST",
      path: "/orders-history",
      token,
      host,
      params: {
        startTime,
        endTime,
        options: { ...options.value },
      },
    });
    dispatch(setIsOrdersHistoryLoading(false));

    if (!response.data.errorCode) {
      dispatch(setOrdersHistory(response.data.ordersHistory));
    }
  } catch (e) {
    dispatch(setIsOrdersHistoryLoading(false));
    console.error(e);
  }
};

export const setOpenOrdersThunk =
  (data, replace = false) =>
  (dispatch, getState) => {
    if (replace) {
      dispatch(setOpenOrders(data));
      return;
    }

    const openOrders = getProviderOpenOrders(getState());

    const { positions, orders } = data;

    const filteredPositions = positions.reduce((res, position) => {
      const isExisting = openOrders.positions.find((pos) => pos.orderId === position.orderId);

      if (!isExisting) {
        res.push(position);
      }

      return res;
    }, []);

    const filteredOrders = orders.reduce((res, order) => {
      const isExisting = openOrders.orders.find((o) => o.orderId === order.orderId);

      if (!isExisting) {
        res.push(order);
      }

      return res;
    }, []);

    const updatedOpenOrders = {
      positions: [...openOrders.positions, ...filteredPositions],
      orders: [...openOrders.orders, ...filteredOrders],
    };

    dispatch(setOpenOrders(updatedOpenOrders));
  };

export const placeOrderThunk = (data) => async (dispatch, getState) => {
  const state = getState();
  const provider = getActiveProvider(state);
  const positionSizeUnitLabel = provider.fields.ordersPositionSize.unit.label;
  const providerOptions = getSelectedOption(state);
  const quickBuyStatus = getQuickBuyStatus(state);
  const token = getProviderToken(state);
  const isPro = getAccountInfoLicense(state) === "PRO";
  const { crypto, forex } = getAssets(state);
  const contract = getProviderContract(state, data.symbol);
  const host = getProvidersApiHost(getState());

  if (!contract) {
    NotificationManager.error("Invalid symbol", provider.name);
    return;
  }

  if (provider.name.toLowerCase() === "simplefx" && crypto.includes(data.symbol)) {
    NotificationManager.error("Invalid symbol", provider.name);
    return;
  }

  if (provider.name.toLowerCase() !== "simplefx" && forex.includes(data.symbol)) {
    NotificationManager.error("Invalid symbol", provider.name);
    return;
  }

  if (!isPro) {
    NotificationManager.error("This feature is only available for PRO", "xSignals");
    return;
  }

  if (!provider.connectionStatus) {
    NotificationManager.error("Provider not connected", provider.name);
    return;
  }

  if (!providerOptions) {
    NotificationManager.error("Please select account options in Settings", provider.name);
    return;
  }

  const { price, slPrice, tpPrice, direction, orderType, limitPrice } = data;

  if (orderType === "limit") {
    if (direction === 1) {
      if (limitPrice > tpPrice) {
        NotificationManager.error("Take profit cannot be less than limit price", provider.name);
        return;
      }
      if (limitPrice < slPrice) {
        NotificationManager.error("Stop loss cannot be less than limit price", provider.name);
        return;
      }
    }
    if (direction === -1) {
      if (limitPrice > slPrice) {
        NotificationManager.error("Stop loss cannot be less than limit price", provider.name);
        return;
      }
      if (limitPrice < tpPrice) {
        NotificationManager.error("Limit price should be higher than take profit", provider.name);
        return;
      }
    }
  }

  if (orderType === "market") {
    if (direction === 1) {
      if (tpPrice < price) {
        NotificationManager.error("Take Profit should be higher Open Price", provider.name);
        return;
      }
      if (slPrice > price) {
        NotificationManager.error("Stop Loss should be less Open Price", provider.name);
        return;
      }
    }
    if (direction === -1) {
      if (tpPrice > price) {
        NotificationManager.error("Take Profit should be less Open Price", provider.name);
        return;
      }
      if (slPrice < price) {
        NotificationManager.error("Stop Loss should be higher Open Price", provider.name);
        return;
      }
    }
  }

  if (!token) return;

  const placeOrder = async () => {
    dispatch(setLoadingOrderPlacement(true));

    const userData = {
      chartPlayerId: data.chartPlayerData ? data.chartPlayerData.id : null,
    };

    const requestData = {
      ...data,
      userData,
      provider: provider.name,
      options: {
        ...providerOptions.value,
      },
    };

    try {
      const response = await _requestProvider({
        method: "POST",
        path: "/place-order",
        token,
        host,
        params: requestData,
      });

      dispatch(setLoadingOrderPlacement(false));
      if (response.data?.errorCode === 0) {
        NotificationManager.success("Order placed", provider.name);

        if (data.chartPlayerData) {
          dispatch(saveChart(data.chartPlayerData));
        }

        dispatch(accountRequestThunk());
        dispatch(connectQuotesSocket({ symbol: data.symbol }));
      } else {
        NotificationManager.error(response.data.errorMessage || "Unexpected error", provider.name);
      }
    } catch (e) {
      console.error(e);
      dispatch(setLoadingOrderPlacement(false));
      if (e.response?.status === 401) {
        NotificationManager.error(e.response.data.errorMessage, provider.name);
        return;
      }
      if (e.response?.data?.error) {
        NotificationManager.error(e.response.data?.message, provider.name);
      } else {
        NotificationManager.error("An error occured, please try again later", "Error");
      }
    }
  };

  if (quickBuyStatus) {
    await placeOrder();
    return;
  }

  const [[optionKey, optionValue]] = Object.entries(providerOptions.value);

  confirmAlert({
    text: `Place ${orderType} order ${data.symbol} ${data.volume} ${positionSizeUnitLabel} with ${optionKey} ${optionValue}?`,
    onCancel: () => {},
    onConfirm: async () => {
      await placeOrder();
    },
  });
};

export const closeAllPositionsThunk = () => async (dispatch, getState) => {
  const state = getState();
  const token = getProviderToken(state);
  const providerOptions = getSelectedOption(state);
  const provider = getActiveProvider(state);
  const quickBuyStatus = getQuickBuyStatus(state);
  const { positions } = getProviderOpenOrders(state);
  const host = getProvidersApiHost(getState());

  if (!positions.length) return;

  const closeAllPositions = async () => {
    try {
      const response = await _requestProvider({
        method: "POST",
        path: "/close-many-positions",
        token,
        host,
        params: {
          positions: positions.map((position) => ({
            symbol: position.symbol,
            orderId: position.orderId,
            volume: position.size,
            closeDirection: position.side === 1 ? -1 : 1,
          })),
          options: {
            ...providerOptions.value,
          },
        },
      });

      if (response.data.errorCode === 0) {
        dispatch(accountRequestThunk());
        NotificationManager.success("All positions closed", provider.name);
        return;
      }

      NotificationManager.error("Unexpected error", provider.name);
    } catch (e) {
      if (e.response.status === 401) {
        NotificationManager.error(e.response.data.errorMessage, provider.name);
        return;
      }
      dispatch(accountRequestThunk());
      NotificationManager.error("Unexpected error", "xSignals");
    }
  };

  if (quickBuyStatus) {
    await closeAllPositions();
    return;
  }

  confirmAlert({
    text: `Are you sure you want to close all positions?`,
    onCancel: () => {},
    onConfirm: async () => {
      await closeAllPositions();
    },
  });
};

export const calculatePnlThunk = (quotes) => (dispatch, getState) => {
  const state = getState();
  const { positions } = getProviderOpenOrders(state);

  const position = positions.find((pos) => pos.symbol === quotes.symbol);
  if (!position) return;

  const contract = getProviderContract(state, position.symbol);
  if (!contract) return;

  const { bid, ask, close } = quotes;

  // bid & ask для crypto
  const cryptoBid = close * (1 + 0.001);
  const cryptoAsk = close * (1 - 0.001);

  let currencyRate = 1;

  // вычисление currencyRate для forex
  if (contract.priceCurrency !== "USD" && contract.type?.toLowerCase() === "forex") {
    const priceCurrency = position.symbol.replace(contract.marginCurrency, "");
    const additionalQuotes = getAllAdditionalQuotes(state);
    const quote = additionalQuotes.find(
      (q) => q.symbol === `USD${priceCurrency}` || q.symbol === `${priceCurrency}USD`
    );

    if (!quote) return;

    if (quote.symbol.startsWith("USD")) {
      currencyRate = 1 / quote.close;
    } else {
      currencyRate = quote.close / 1;
    }
  }

  const pointValue = contract.contractSize * currencyRate;

  const swap = position.swap || 0;

  let pnl;

  if (contract.type?.toLowerCase() === "forex") {
    pnl =
      position.side === -1
        ? swap + position.size * (position.entryPrice - ask) * pointValue
        : swap + position.size * (bid - position.entryPrice) * pointValue;
  } else {
    pnl =
      position.side === -1
        ? swap + position.size * (position.entryPrice - cryptoAsk) * pointValue
        : swap + position.size * (cryptoBid - position.entryPrice) * pointValue;
  }

  dispatch(setPositionPnl({ orderId: position.orderId, pnl: pnl.toFixed(3) }));
};

// export const requestOpenOrdersThunk = (symbol) => async (dispatch, getState) => {
//   const providerOptions = getSelectedOption(getState());
//   const token = getProviderToken(getState());
//
//   try {
//     const data = {
//       symbol,
//       token,
//       options: {
//         ...providerOptions.value,
//       },
//     };
//
//     const response = await _requestProvider({ method: "POST", path: "/open-orders", token, params: data });
//
//     if (response.data?.errorCode === 0) {
//       const { positions, orders } = response.data;
//
//       dispatch(setOpenOrdersThunk({ positions, orders }, true));
//     }
//   } catch (e) {
//     if (e.response?.status === 401) {
//       dispatch(setOpenOrdersThunk({ positions: [], orders: [] }, true));
//     }
//   }
// };

// export const requestClosePositionThunk = (orderId) => async (dispatch, getState) => {
//   const providerOptions = getSelectedOption(getState());
//   const token = getProviderToken(getState());
//   const openOrders = getProviderOpenOrders(getState());
//   const activeProvider = getActiveProvider(getState());
//
//   const size = openOrders.positions.find((position) => position.orderId === orderId)?.size;
//
//   const confirm = window.confirm("Are you sure you want to close the position?");
//
//   if (confirm) {
//     try {
//       const data = {
//         orderId,
//         size,
//         token,
//         options: {
//           ...providerOptions.value,
//         },
//       };
//
//       const response = await requestProvider({ method: "POST", path: "/close-position", token, data });
//
//       if (response.data?.errorCode === 0) {
//         NotificationManager.success("Position closed", activeProvider.name);
//         dispatch(requestOpenOrdersThunk());
//         return;
//       }
//
//       NotificationManager.error("Close position request error", activeProvider.name);
//     } catch (e) {
//       NotificationManager.error("Unexpected error", activeProvider.name);
//     }
//   }
// };

// export const requestCancelOrderThunk = (orderId, type) => async (dispatch, getState) => {
//   const token = getProviderToken(getState());
//   const providerOptions = getSelectedOption(getState());
//   const activeProvider = getActiveProvider(getState());
//
//   const confirm = window.confirm("Are you sure you want to cancel the order?");
//
//   if (confirm) {
//     try {
//       const data = {
//         token,
//         orderId,
//         type,
//         options: {
//           ...providerOptions.value,
//         },
//       };
//
//       const response = await requestProvider({ method: "POST", path: "/cancel-order", token, data });
//
//       if (response.data?.errorCode === 0) {
//         NotificationManager.success("Order canceled", activeProvider.name);
//         dispatch(requestOpenOrdersThunk());
//         return;
//       }
//
//       NotificationManager.error("Cancel order request error", activeProvider.name);
//     } catch (e) {
//       NotificationManager.error("Cancel order request error", activeProvider.name);
//     }
//   }
// };

// export const requestModifyOrderThunk = (orderId, type, volume) => async (dispatch, getState) => {
//   const token = getProviderToken(getState());
//   const activeProvider = getActiveProvider(getState());
//   const providerOptions = getSelectedOption(getState());
//
//   const confirm = window.confirm("Are you sure that you want to modify the order?");
//
//   if (confirm) {
//     try {
//       const data = {
//         token,
//         orderId,
//         volume,
//         type,
//         options: {
//           ...providerOptions.value,
//         },
//       };
//       const response = await requestProvider({ method: "POST", path: "/modify-order", token, data });
//
//       if (response.data?.errorCode === 0) {
//         NotificationManager.success("Order modified", activeProvider.name);
//         dispatch(requestOpenOrdersThunk());
//         return;
//       }
//
//       NotificationManager.error("Modify order request error", activeProvider.name);
//     } catch (e) {
//       NotificationManager.error("Modify order request error", activeProvider.name);
//     }
//   }
//   dispatch(requestOpenOrdersThunk());
// };

export const deleteAccountDataThunk = (provider) => async (dispatch, getState) => {
  const state = getState();
  const vfxUserId = getAccountUserId(state);
  const token = getProviderToken(state, provider.name);
  const host = getProvidersApiHost(getState());

  try {
    await _requestProvider({
      method: "POST",
      host,
      path: "/delete-account",
      token,
    });

    dispatch(fetchProviders(vfxUserId));
  } catch (e) {}
};

export const getBarsHistoryThunk =
  ({ symbolInfo, interval, periodParams, onHistoryCallback, onErrorCallback }) =>
  async (dispatch, getState) => {
    let provider = "";
    const host = getProvidersApiHost(getState());

    if (symbolInfo.type === "forex") {
      provider = "simplefx";
    } else {
      const activeProvider = getActiveProvider(getState());
      const contract = getProviderContract(getState(), symbolInfo.symbol);

      provider = activeProvider.name.toLowerCase();

      if (!contract) {
        provider = "binance";
      }
    }

    const { from, to, countBack, firstDataRequest } = periodParams;
    let requestParams = {
      symbol: symbolInfo.name,
      timeframe: interval,
      provider,
      startTime: from * 1000,
      endTime: to * 1000,
      limit: firstDataRequest ? 1000 : countBack,
    };

    try {
      const response = await _requestProvider({
        method: "GET",
        host,
        path: "/get-bars-history",
        params: requestParams,
      });

      if (response.data?.errorCode === 0) {
        onHistoryCallback(response.data.historyBars);
      } else {
        onErrorCallback("Klines data error");
      }
    } catch (e) {
      onErrorCallback("Klines data error");
    }
  };

export const setConnectionErrorThunk =
  (message = "Unexpected server error") =>
  (dispatch) => {
    dispatch(setConnectionError(message));
    setTimeout(() => {
      dispatch(setConnectionError(null));
    }, 10000);
  };

export const setApplySettingsErrorThunk =
  (message = "Unexpected server error") =>
  (dispatch) => {
    dispatch(setApplySettingsError(message));
    setTimeout(() => {
      dispatch(setApplySettingsError(null));
    }, 10000);
  };
