import React from "react";

const AdaptiveAlg = ({ algName, algId, periods, activeId, activeTerm, onPeriodClicked }) => {
  return (
    <div className="signal_type_item">
      {/* <!-- Элемент Signal Type с галочкой --> */}
      {/* <!-- Изначально выглядит пассивным. При добавлении класса active становится активным. Галочка становится  --> */}
      <div className={`signal_type_name_box ${algId === activeId.toString() ? "active" : ""}`}>
        <span></span>
        <div className="signal_type_name">{algName}</div>
      </div>
      {/* <!-- Элемент Signal Type с галочкой конец --> */}
      <div className="signal_type_graph">
        {/* <!-- Цифровые значения Signal Type --> */}
        {/* <!-- Изначально все элементы пассивные. При добавлении класса active становится активным. Цифра обводится в круглешок и становится белой  --> */}
        {periods?.map(({ label, value }, i) => (
          <div
            className={algId === activeId.toString() && activeTerm === value ? "active" : ""}
            key={i}
            onClick={() => {
              onPeriodClicked({ id: algId, term: value });
            }}
          >
            {label}
          </div>
        ))}
        <div
          className={algId === activeId.toString() && !activeTerm ? "active" : ""}
          onClick={() => {
            onPeriodClicked({ id: algId, term: null });
          }}
        >
          All
        </div>
        {/* <!-- Цифровые значения Signal Type конец --> */}
      </div>
    </div>
  );
};

export default AdaptiveAlg;
