import { combineReducers } from "redux";
import { handleActions } from "redux-actions";
import { saveChart, setSavedCharts } from "./actions";

/*
  Saved chart object
 * {
 *   id: uid,
 *   chartData: chartData,
 *   createdAt: timestamp
 * }
 */

const savedCharts = handleActions(
  {
    [setSavedCharts]: (state, action) => action.payload,
    [saveChart]: (state, action) => {
      state.push(action.payload);
      return [...state];
    },
  },
  []
);

export default combineReducers({
  savedCharts,
});
