import React, { Component } from "react";
import PropTypes from "prop-types";
import View from "./View";
import moment from "moment";
import { connect } from "react-redux";
import { newChartTabThunk, setChartTabStrategy } from "../../../../modules/client";
import { placeOrderThunk } from "../../../../modules/providers";
import { toggleSignalFavorite } from "../../../../modules/filtersSettings";
import { getSLTP } from "../../../../utils";

class Signal extends Component {
  static propTypes = {
    currentTms: PropTypes.number.isRequired,
    variant: PropTypes.number.isRequired,
    algorithms: PropTypes.array.isRequired,
    showFull: PropTypes.bool.isRequired,
    ordersStrategy: PropTypes.array.isRequired,
    isAdaptive: PropTypes.bool,
    adaptiveRolled: PropTypes.bool,
    data: PropTypes.shape({
      symbol: PropTypes.string.isRequired,
      timestamp: PropTypes.number.isRequired,
      expiration: PropTypes.number.isRequired,
      signal: PropTypes.number.isRequired,
      alg: PropTypes.number.isRequired,
      price: PropTypes.string.isRequired,
      power: PropTypes.number.isRequired,
      hm: PropTypes.shape({
        m1: PropTypes.number.isRequired,
        m5: PropTypes.number.isRequired,
        m15: PropTypes.number.isRequired,
        m30: PropTypes.number.isRequired,
        h1: PropTypes.number.isRequired,
        h4: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
    onSetSymbolClicked: PropTypes.func.isRequired,
    onOpenChartsClicked: PropTypes.func.isRequired,
    onRolled: PropTypes.func,
    onReverse: PropTypes.func,
  };

  constructor(props) {
    super(props);

    const {
      ordersStrategy,
      data: { price, atr20, signal, symbol_point },
    } = this.props;

    let take_profit = 0;
    let stop_loss = 0;
    const strategy = ordersStrategy[2] || null;

    if (strategy) {
      const sltp = getSLTP(strategy, price, atr20, symbol_point, signal);
      take_profit = sltp.take_profit;
      stop_loss = sltp.stop_loss;
    }

    this.state = {
      strategy: this.props.ordersStrategy[2] || null,
      take_profit,
      stop_loss,
    };
  }

  handleOpenChartsClicked = (term, symbol, price) => {
    const {
      newChartTabThunk,
      data: { signal, atr20, symbol_point, timestamp },
    } = this.props;
    const { take_profit, stop_loss, strategy } = this.state;

    newChartTabThunk({
      term,
      symbol,
      take_profit,
      stop_loss,
      price,
      signal,
      atr20,
      symbol_point,
      strategy,
      timestamp: timestamp * 1000,
    });
  };

  handleToggleFavorites = () => {
    const {
      toggleSignalFavorite,
      data: { symbol },
    } = this.props;

    toggleSignalFavorite(symbol);
  };

  handleChangeStrategy = (strategy) => {
    const {
      setChartTabStrategy,
      showFull,
      data: { price, atr20, signal, symbol_point, symbol, period },
    } = this.props;
    if (!showFull) return;

    const { take_profit, stop_loss } = getSLTP(strategy, price, atr20, symbol_point, signal);

    this.setState({
      strategy,
      take_profit,
      stop_loss,
    });

    setChartTabStrategy({ id: symbol + period, data: { strategy, stop_loss, take_profit } });
    // setChartTabData({ id: symbol + period, data: { strategy, stop_loss, take_profit } });
  };

  handlePlaceOrderClicked = ({ volume }) => {
    const {
      placeOrderThunk,
      data: { signal, symbol, price },
    } = this.props;

    const { take_profit, stop_loss } = this.state;

    placeOrderThunk({
      volume,
      price,
      tpPrice: take_profit,
      slPrice: stop_loss,
      symbol,
      orderType: "market",
      direction: signal,
    });
  };

  render() {
    const {
      data: { alg, power, timestamp },
      showFull,
      // currentTms,
      variant,
      algorithms,
      isFavorite,
      isLoadingOrderPlacement,
    } = this.props;

    const { take_profit, stop_loss } = this.state;

    const updated = moment(moment().diff(timestamp * 1000)).format("mm:ss");
    // const updatedServerSync = moment(
    //   moment(currentTms).diff(timestamp * 1000)
    // ).format("mm:ss");
    // const updatedServerSync = new Date(currentTms - timestamp * 1000).toISOString().substr(14, 5);

    const data = {
      ...this.props.data,
      updated: updated,
      alg: algorithms.find(({ deprecated_key }) => alg == deprecated_key)?.short_title,
      power: power + 1,
      take_profit,
      stop_loss,
      showFull,
      variant,
      // updatedServerSync,
    };

    return (
      <View
        {...data}
        isAdaptive={this.props.isAdaptive}
        adaptiveRolled={this.props.adaptiveRolled}
        isFavorite={isFavorite}
        orderStrategyId={this.state.strategy.id}
        orderStrategyList={this.props.ordersStrategy}
        isLoadingOrderPlacement={isLoadingOrderPlacement}
        onSetSymbolClicked={this.props.onSetSymbolClicked}
        onOpenChartsClicked={this.handleOpenChartsClicked}
        onPlaceOrderClicked={this.handlePlaceOrderClicked}
        onChangeStrategy={this.handleChangeStrategy}
        onToggleFavorite={this.handleToggleFavorites}
        onRolled={this.props.onRolled}
        onReverse={this.props.onReverse}
      />
    );
  }
}

export default connect(() => ({}), {
  toggleSignalFavorite,
  newChartTabThunk,
  placeOrderThunk,
  setChartTabStrategy,
})(Signal);
