class ChartPlayer {
  constructor({ historyBars, side, stopLoss, takeProfit, chartEventEmitter, closeTime, onRealtimeCallback }) {
    this.historyBars = historyBars;
    this.onRealtimeCallback = onRealtimeCallback;
    this.side = side;
    this.stopLoss = stopLoss;
    this.takeProfit = takeProfit;
    this.chartEventEmitter = chartEventEmitter;
    this.closeTime = closeTime;
  }

  isPositionClosed = false;
  isPause = false;
  lastCandle = null;
  lastTick = 0;

  defaultSpeed = 20;
  x2Speed = 10;
  x4Speed = 5;
  currentSpeed = this.defaultSpeed;

  async play() {
    if (this.isPause) {
      this.isPause = false;
    }

    for (const candle of this.historyBars) {
      this.lastCandle = candle;

      let { open, close, low, high, volume, time } = candle;

      if (time >= this.closeTime && !this.isPositionClosed) {
        this.pause();
        this.isPositionClosed = true;
        this.chartEventEmitter.emit("positionClosed");
      }

      volume = parseFloat(volume);

      const realtimeCandle = {
        open,
        low: open,
        high: open,
        close: open,
        time,
        volume: 0,
      };

      const startDirection =
        Math.abs(candle.open - candle.low) < Math.abs(candle.open - candle.high) ? "low" : "high";
      let direction = startDirection;

      const countTicks = 15;

      const tickStep = (high - low) / countTicks;
      const volumeTick = volume / countTicks;

      let isEnd = false;
      let isHighEnd = false;
      let isLowEnd = false;

      while (!isEnd) {
        if (this.isPause) {
          return;
        }

        // if (!this.isPositionClosed) {
        //   if (this.side === -1) {
        //     if (this.stopLoss <= realtimeCandle.close) {
        //       this.chartEventEmitter.emit("pause");
        //       this.isPositionClosed = true;
        //     }
        //     if (this.takeProfit >= realtimeCandle.close) {
        //       this.chartEventEmitter.emit("pause");
        //       this.isPositionClosed = true;
        //     }
        //   } else {
        //     if (this.stopLoss >= realtimeCandle.close) {
        //       this.chartEventEmitter.emit("pause");
        //       this.isPositionClosed = true;
        //     }
        //     if (this.takeProfit <= realtimeCandle.close) {
        //       this.chartEventEmitter.emit("pause");
        //       this.isPositionClosed = true;
        //     }
        //   }
        // }

        if (direction === "low") {
          if (realtimeCandle.close - tickStep > low && !isLowEnd) {
            realtimeCandle.close -= tickStep;
          } else {
            realtimeCandle.low = low;
            isLowEnd = true;
            if (startDirection === "low") {
              direction = "high";
            } else {
              if (realtimeCandle.close + tickStep < close) {
                realtimeCandle.close += tickStep;
              } else {
                realtimeCandle.close = close;
                realtimeCandle.high = high;

                while (realtimeCandle.close - tickStep > close) {
                  realtimeCandle.close -= tickStep;

                  realtimeCandle.volume += volumeTick;
                  this.onRealtimeCallback(realtimeCandle);
                  await this.delay(this.currentSpeed);
                }

                realtimeCandle.close = close;

                realtimeCandle.volume += volumeTick;
                this.onRealtimeCallback(realtimeCandle);
                await this.delay(this.currentSpeed);
                isEnd = true;
              }
            }
          }
        } else {
          if (realtimeCandle.close + tickStep < high && !isHighEnd) {
            realtimeCandle.close += tickStep;
          } else {
            realtimeCandle.high = high;
            isHighEnd = true;
            if (startDirection === "high") {
              direction = "low";
            } else {
              if (realtimeCandle.close - tickStep > close) {
                realtimeCandle.close -= tickStep;
              } else {
                realtimeCandle.close = close;
                realtimeCandle.low = low;

                while (realtimeCandle.close + tickStep < close) {
                  realtimeCandle.close += tickStep;

                  realtimeCandle.volume += volumeTick;
                  this.onRealtimeCallback(realtimeCandle);
                  await this.delay(this.currentSpeed);
                }

                realtimeCandle.close = close;

                realtimeCandle.volume += volumeTick;
                this.onRealtimeCallback(realtimeCandle);
                await this.delay(this.currentSpeed);
                isEnd = true;
              }
            }
          }
        }

        if (!isEnd) {
          realtimeCandle.volume += volumeTick;
          this.onRealtimeCallback(realtimeCandle);
          await this.delay(this.currentSpeed);
        }
      }
    }
    this.isPlayingEnd = true;
  }

  pause() {
    try {
      this.isPause = true;
      const lastCandleIndex = this.historyBars.findIndex((candle) => candle.time === this.lastCandle.time);
      this.historyBars = this.historyBars.slice(lastCandleIndex);
    } catch (e) {}
  }

  changeSpeed(speed) {
    switch (speed) {
      case "default":
        this.currentSpeed = this.defaultSpeed;
        break;
      case "x2":
        this.currentSpeed = this.x2Speed;
        break;
      case "x4":
        this.currentSpeed = this.x4Speed;
        break;
      default:
        this.currentSpeed = this.defaultSpeed;
    }
  }

  delay(ms) {
    return new Promise((res) => {
      setTimeout(res, ms);
    });
  }
}

export default ChartPlayer;
