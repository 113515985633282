import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getSignalTypesList, getSignalPeriods } from "../../../../modules/initial";
import {
  getActiveSignalsType,
  setActiveSignalsType,
} from "../../../../modules/client";
import { isClientPRO } from "../../../../modules/mainSettings";

import View from "./View";
import { getFiltersPresets } from "../../../../modules/filtersSettings";

class TypeSettings extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    isPro: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    availableTypesList: PropTypes.object.isRequired,
    activeSignalsType: PropTypes.shape({
      id: PropTypes.string.isRequired,
      term: PropTypes.number,
    }).isRequired,
  };

  handleChangeSignalsType = ({ id, term }) => {
    this.props.setActiveSignalsType({ id, term });
    setTimeout(this.props.handleClick, 300);
  };

  render() {
    const { availableTypesList, isActive, activeSignalsType, isPro, periods } = this.props;

    let { presets } = this.props;

    let sortedTypes = Object.entries(availableTypesList);
    if (!isPro)
      sortedTypes = sortedTypes.filter(
        ([id, name]) => [10, 20, 11, 21].indexOf(parseInt(id)) !== -1
      );

    presets = presets.sort((a, b) => a.createdAt - b.createdAt);

    return (
      <View
        {...{ isActive, presets, periods }}
        list={sortedTypes}
        onClick={this.handleChangeSignalsType}
        activeItem={activeSignalsType}
      />
    );
  }
}

export default connect(
  (state) => ({
    availableTypesList: getSignalTypesList(state),
    activeSignalsType: getActiveSignalsType(state),
    presets: getFiltersPresets(state),
    isPro: isClientPRO(state),
    periods: getSignalPeriods(state)
  }),
  { setActiveSignalsType }
)(TypeSettings);
