import React, { Component } from "react";
import PropTypes from "prop-types";
import SignalsList from "./items/SignalsList";
import BrokerSettings from "./items/BrokerSettings";
import TypeSettings from "./items/TypeSettings";
import { connect } from "react-redux";
import { getSignals } from "../../modules/signals";

class LeftPanel extends Component {
  static propTypes = {
    showSignalsList: PropTypes.bool.isRequired,
    showBrokerSettings: PropTypes.bool.isRequired,
    typeSettings: PropTypes.shape({
      handleClick: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired,
    }).isRequired,
  };

  render() {
    const { signals, showSignalsList, typeSettings, showBrokerSettings } = this.props;

    return (
      <>
        <SignalsList
          signals={signals}
          isActive={showSignalsList || showBrokerSettings || typeSettings.isActive}
        />
        <BrokerSettings isActive={showBrokerSettings} />
        <TypeSettings {...typeSettings} />
      </>
    );
  }
}

export default connect((state) => ({
  signals: getSignals(state),
}))(LeftPanel);
