import React, { useRef, useCallback } from "react";

const useDebounce = (cb, delay) => {
  const timer = useRef(null);

  const debounceCallback = useCallback(
    (...args) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        cb(...args);
      }, delay);
    },
    [cb, delay]
  );

  return debounceCallback;
};

export default useDebounce;
