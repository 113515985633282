import SignalsContainer from "./SignalsContainer";

const View = ({ children, isActive, activePresetName, activePeriod }) => {
  return (
    <>
      {/* <!-- Список с фильтром --> */}
      {/* <!-- Изначально скрыт, появляется при добавлении класса active --> */}
      <div className={`filters ${isActive ? "active" : ""}`}>
        <SignalsContainer activePresetName={activePresetName} activePeriod={activePeriod}>
          {children}
        </SignalsContainer>
        {/* <!-- Список с фильтром конец --> */}
      </div>
    </>
  );
};

export default View;
